<template>
  <div class="grid">
    <div v-if="savingState" style="position:fixed;width:100%;height:100%;top:0;left:0;background:white;z-index:99;display: flex;justify-content: center;align-items: center; flex-direction: column;">
            <h3>Сохранение результатов</h3>
            <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" fill="transparent" />
          </div>
    <div :class="{ 'col-8': isCheck, 'col': !isCheck }" style="max-width:100%;">
      <span v-if="errorMessage">Проверьте CourseId, ModuleID и наличие StudentID</span>
      <div v-if="isValidParams" class="survey-page m-0 p-0">
        <h1 v-if="showDebugPanel">{{ survey.title }}</h1>
        <div v-if="isDataLoaded">
          <div v-if="currentScreen">
            <div v-if="!isSurveyCompleted" class="screen-content">
              <h2 v-if="showDebugPanel">{{ currentScreen.title }}</h2>
              <b v-if="studentId === '1'">Это тестовый студент - данные не сохраняются</b>
              <!-- Отображение элементов экрана -->
              <div v-for="element in filteredElements" :key="element.id" class="screen-element">

                <!-- Отображение текстовых элементов -->
                <div v-if="element.element_type === 'TEXT'" v-html="element.content"></div>
                <!-- Отображение вопросов -->
                <div v-if="element.element_type === 'QUESTION'">
                  <h4 class="mb-2 mt-4" :class="{ 'question-error': !isQuestionAnswered(element.question.id) }">
                    {{ element.question.title }}
                  </h4>
                  <!-- Определение типа сетки -->
                  <div v-if="element.question.question_type === 'GRID' && element.question.child_questions"
                    class="table-container">
                    <!-- Определяем колонки и ряды -->
                    <table class="grid-table">
                      <thead>
                        <tr>
                          <th></th>
                          <th style="font-size:12px;font-weight:400;" v-for="answer in element.question.answers" :key="answer.id">{{ answer.title }}</th>
                        </tr>
                      </thead>
                      <tbody v-if="!element.question.has_dynamic_rows">
                        <tr v-for="rowQuestion in element.question.child_questions" :key="rowQuestion.id">
                          <td :class="{ 'highlight-red': unansweredQuestions.has(rowQuestion.id) }"><p v-if="!rowQuestion.is_open">{{ rowQuestion.title
                          }}</p><p class="mb-1" v-else>Другой<InputText  v-model="rowQuestion.title" /></p></td>
                          <td v-for="answer in element.question.answers" :key="answer.id" :data-label="answer.title">
                            <!-- Логика для обычных типов гридов -->
                            <div v-if="element.question.grid_type === 'SINGLE'" class="text-center">
                              <RadioButton :inputId="`grid_${rowQuestion.id}_${answer.id}`" :value="answer.id"
                                v-model="selectedAnswers[rowQuestion.id]" />
                            </div>
                            <div v-else-if="element.question.grid_type === 'MULTIPLE' && !answer.is_none"
                              class="text-center">
                              <Checkbox :inputId="`grid_${rowQuestion.id}_${answer.id}`" :value="answer.id"
                                v-model="selectedAnswers[rowQuestion.id]" :disabled="isNoneSelected(rowQuestion.id)" />
                            </div>
                            <div v-else-if="element.question.grid_type === 'NUM' && !answer.is_none">
                              <InputNumber v-model="selectedAnswers[rowQuestion.id]" :min="answer.min_value"
                                :max="answer.max_value" mode="decimal" showButtons buttonLayout="horizontal"
                                 />
                            </div>
                            <div v-else-if="element.question.grid_type === 'SCALE' && !answer.is_none">
                              
                              <ScaleTemplate v-model="selectedAnswers[rowQuestion.id]" :stars="answer.max_value"
                                :isNone="isNoneSelected(rowQuestion.id)" />
                            </div>
                            <div v-else-if="answer.is_none && element.question.grid_type != 'SINGLE'">
                              <Checkbox v-if="element.question.grid_type != 'SCALE'" :inputId="`answer_${answer.id}`" :value="answer.id"
                                v-model="selectedAnswers[rowQuestion.id]"
                                @change="handleNoneAnswerChange(rowQuestion.id, answer.id)"
                                :checked="isNoneSelected(rowQuestion.id)" class="is-none" />
                                <RadioButton v-if="element.question.grid_type === 'SCALE'" :inputId="`answer_${answer.id}`" :value="answer.title"
                                v-model="selectedAnswers[rowQuestion.id]"
                                @change="handleNoneAnswerChange(rowQuestion.id, answer.id)"
                                :checked="isNoneSelected(rowQuestion.id)" class="is-none" />
                            </div>
                            <!-- Логика для Custom гридов -->
                            <template v-else-if="element.question.grid_type === 'CUSTOM'">

                              <div v-if="answer.answer_type === 'REFERENCE'">
                                <Select v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                  :options="referenceOptions[element.question.id + '_' + answer.id]" filter
                                  optionLabel="label" optionValue="value" placeholder="Выберите значение" 
                                  :virtualScrollerOptions="{ itemSize: 36 }"
                                  :loading="!referenceOptions[element.question.id + '_' + answer.id] || referenceOptions[element.question.id + '_' + answer.id].length === 0"
                                  />
                              </div>
                              <div v-else-if="answer.answer_type === 'NUM'">
                                <InputNumber v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                  :min="answer.min_value" :max="answer.max_value" mode="decimal" showButtons
                                  buttonLayout="horizontal"  />
                              </div>
                              <div v-else-if="answer.answer_type === 'SCALE'">
                                <ScaleTemplate v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                  :stars="answer.max_value" />
                              </div>
                            </template>
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <!-- HARDCODE Q-33  -->
                        <template v-if="element.question.id === 225 || element.question.id == 358 || element.question.id == 482 || element.question.id == 701">
                          <template
                            v-for="(rowQuestion, rowIndex) in element.question.child_questions.slice(0, element.question.visibleRows)"
                            :key="rowQuestion.id">
                            <!-- Добавляем строку с дропдаунами перед каждым 9-м элементом -->
                            <tr v-if="rowIndex % 9 === 0">
                              <td :colspan="element.question.answers.length + 1" :class="{ 'highlight-red': unansweredQuestions.has(rowQuestion.id) }">
                                <b style="display:block;margin-top:10px;">Выберите компанию и Торговое наименование</b>
                                <div class="dropdown-container flex md:flex-row flex-column">

                                  <Select style="width:250px!important"
                                    v-model="dropdownValues['dropdownOne_' + rowIndex]"
                                    :options="referenceOptions[9999 + rowIndex]" filter optionLabel="label" optionValue="value"
                                    placeholder="Компания" class="mt-3 mb-2 mr-3"
                                    @change="handleFirstDropdownChange(rowIndex, rowQuestion.id)" 
                                    :virtualScrollerOptions="{ itemSize: 36 }"
                                    :loading="!referenceOptions[9999 + rowIndex] || referenceOptions[9999 + rowIndex].length === 0"
                                    />
                                  <Select style="width:250px!important;"
                                    v-model="dropdownValues['dropdownTwo_' + rowIndex]"
                                    :options="referenceOptions[88880 + rowIndex]" filter optionLabel="label"
                                    optionValue="value" placeholder="Торговое наименование" class="mt-3 mb-2 mr-3"
                                    @change="handleSecondDropdownChange(rowIndex, rowQuestion.id)" 
                                    :virtualScrollerOptions="{ itemSize: 36 }"
                                    :loading="!referenceOptions[88880 + rowIndex] || referenceOptions[88880 + rowIndex].length === 0"
                                    />
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td data-label="Вопрос">
                                <p>{{ referenceOptions[element.question.id] && referenceOptions[element.question.id][rowIndex % referenceOptions[element.question.id].length] ? referenceOptions[element.question.id][rowIndex % referenceOptions[element.question.id].length].label : '' }}</p>
                                <InputText type="hidden" :class="{ 'highlight-red': unansweredQuestions.has(rowQuestion.id) }"
                                  style="border:none;" v-model="selectedAnswers[rowQuestion.id]"
                                  :value="referenceOptions[element.question.id] && referenceOptions[element.question.id][rowIndex % referenceOptions[element.question.id].length] ? referenceOptions[element.question.id][rowIndex % referenceOptions[element.question.id].length].label : ''">
                                </InputText>
                              </td>
                              <td v-for="answer in element.question.answers" :key="answer.id" :data-label="answer.title">
                                <!-- Логика для обычных типов гридов -->
                                <div v-if="element.question.grid_type === 'SINGLE'" class="text-center">
                                  <RadioButton :inputId="`grid_${rowQuestion.id}_${answer.id}`" :value="answer.id"
                                    v-model="selectedAnswers[rowQuestion.id]" />
                                </div>
                                <div v-else-if="element.question.grid_type === 'MULTIPLE'" class="text-center">
                                  <Checkbox :inputId="`grid_${rowQuestion.id}_${answer.id}`" :value="answer.id"
                                    v-model="selectedAnswers[rowQuestion.id]" />
                                </div>
                                <div v-else-if="element.question.grid_type === 'NUM'">
                                  <InputNumber v-model="selectedAnswers[rowQuestion.id]" :min="answer.min_value"
                                    :max="answer.max_value" mode="decimal" showButtons buttonLayout="horizontal"
                                     />
                                </div>
                                <div v-else-if="element.question.grid_type === 'SCALE'">
                                  <ScaleTemplate v-model="selectedAnswers[rowQuestion.id]" :stars="answer.max_value" />
                                </div>
                                <!-- Логика для Custom гридов -->
                                <template v-else-if="element.question.grid_type === 'CUSTOM'">
                                  <div v-if="answer.answer_type === 'REFERENCE' && !element.question.has_dynamic_rows">
                                    <Select v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                      :options="referenceOptions[element.question.id + '_' + answer.id]" filter
                                      optionLabel="label" optionValue="value" placeholder="Выберите значение" 
                                      :virtualScrollerOptions="{ itemSize: 36 }"
                                      :loading="!referenceOptions[element.question.id + '_' + answer.id] || referenceOptions[element.question.id + '_' + answer.id].length === 0"
                                      />
                                  </div>
                                  <div v-if="answer.answer_type === 'REFERENCE' && element.question.has_dynamic_rows"
                                    style="display:none;">
                                    <Select v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                      :options="referenceOptions[element.question.id + '_' + rowQuestion.id + '_' + answer.id]"
                                      filter optionLabel="label" optionValue="value" placeholder="Выберите значение"
                                      @change="filterReferenceDataByColumn(element.question.id, rowQuestion.id, answer.id)" 
                                      :virtualScrollerOptions="{ itemSize: 36 }"
                                      :loading="!referenceOptions[element.question.id + '_' + rowQuestion.id + '_' + answer.id] || referenceOptions[element.question.id + '_' + rowQuestion.id + '_' + answer.id].length === 0"
                                      />
                                  </div>
                                  <div v-else-if="answer.answer_type === 'NUM'">
                                    <InputNumber v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                      :min="answer.min_value" :max="answer.max_value" mode="decimal" showButtons
                                      buttonLayout="horizontal"  />
                                  </div>
                                  <div v-else-if="answer.answer_type === 'SCALE'">
                                    <ScaleTemplate v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                      :stars="answer.max_value" />
                                  </div>
                                </template>
                              </td>
                            </tr>
                          </template>
                        </template>
                        <!--HARDCODE Q-29 -->
                        <template v-else-if="element.question.id === 320 || element.question.id === 602">
                          <tr
                            v-for="(rowQuestion, index) in element.question.child_questions.slice(0, element.question.visibleRows)"
                            :key="rowQuestion.id"
                            style=" vertical-align: top;"
                            :class="{'hidden-duplicate': isDuplicate(index) && !visibleRows.includes(index)}"
                            >
                            <td :class="{ 'highlight-red': unansweredQuestions.has(rowQuestion.id) }">
                              <div :class="{'do-not-show': isDuplicate(index)}">
                              <Select v-model="selectedAnswers[rowQuestion.id]"
                                :options="referenceOptions[element.question.id]" filter optionLabel="label"
                                optionValue="value" placeholder="Выберите значение"
                                @change="filterReferenceDynamicDataByKey(element.question.id, rowQuestion.id)" :disabled="disabledDropdowns[rowQuestion.id]"
                                :virtualScrollerOptions="{ itemSize: 36 }"
                                :loading="!referenceOptions[element.question.id] || referenceOptions[element.question.id].length === 0"
                                />
                              </div>
                              <Button v-if="isFirstRowInGroup(index)" @click="showNextHiddenRow(index)" link>+ добавить препарат</Button>
                            </td>
                            <td v-for="answer in element.question.answers" :key="answer.id" style="width:185px!important">
                              <!-- Логика для обычных типов гридов -->
                              <div v-if="element.question.grid_type === 'SINGLE'" class="text-center">
                                <RadioButton :inputId="`grid_${rowQuestion.id}_${answer.id}`" :value="answer.id"
                                  v-model="selectedAnswers[rowQuestion.id]" />
                              </div>
                              <div v-else-if="element.question.grid_type === 'MULTIPLE'" class="text-center">
                                <Checkbox :inputId="`grid_${rowQuestion.id}_${answer.id}`" :value="answer.id"
                                  v-model="selectedAnswers[rowQuestion.id]" />
                              </div>
                              <div v-else-if="element.question.grid_type === 'NUM'">
                                <InputNumber v-model="selectedAnswers[rowQuestion.id]" :min="answer.min_value"
                                  :max="answer.max_value" mode="decimal" showButtons buttonLayout="horizontal"
                                  />
                              </div>
                              <div v-else-if="element.question.grid_type === 'SCALE'">
                                
                                <ScaleTemplate v-model="selectedAnswers[rowQuestion.id]" :stars="answer.max_value" />
                              </div>
                              <!-- Логика для Custom гридов -->
                              <template v-else-if="element.question.grid_type === 'CUSTOM'">
                                <div v-if="answer.answer_type === 'REFERENCE' && !element.question.has_dynamic_rows">
                                  <Select v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                    :options="referenceOptions[element.question.id + '_' + answer.id]" filter
                                    optionLabel="label" optionValue="value" placeholder="Выберите значение" 
                                    :virtualScrollerOptions="{ itemSize: 36 }"
                                    :loading="!referenceOptions[element.question.id + '_' + answer.id] || referenceOptions[element.question.id + '_' + answer.id].length === 0"
                                    />
                                </div>
                                <div v-if="answer.answer_type === 'REFERENCE' && element.question.has_dynamic_rows" :class="{'do-not-show': isDuplicate(index) && (answer.id === 445 || answer.id === 444 || answer.id === 902 || answer.id === 903)}">
                                  <Select v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                    :options="referenceOptions[element.question.id + '_' + rowQuestion.id + '_' + answer.id]"
                                    filter optionLabel="label" optionValue="value" placeholder="Выберите значение"
                                    @change="filterReferenceDataByColumn(element.question.id, rowQuestion.id, answer.id)" :disabled="disabledDropdowns[rowQuestion.id + '_' + answer.id]"  
                                    :virtualScrollerOptions="{ itemSize: 36 }"
                                    :loading="!referenceOptions[element.question.id + '_' + rowQuestion.id + '_' + answer.id] || referenceOptions[element.question.id + '_' + rowQuestion.id + '_' + answer.id].length === 0"
                                    />
                            
                                </div>
                                <div v-else-if="answer.answer_type === 'NUM'">
                                  <InputNumber v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                    :min="answer.min_value" :max="answer.max_value" mode="decimal" showButtons
                                    buttonLayout="horizontal"  />
                                </div>
                                <div v-else-if="answer.answer_type === 'SCALE'">
                                  <ScaleTemplate v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                    :stars="answer.max_value" />
                                </div>
                              </template>
                            </td>
                          </tr>
                        </template>
                        <template v-else>
                          <tr
                            v-for="(rowQuestion) in element.question.child_questions.slice(0, element.question.visibleRows)"
                            :key="rowQuestion.id">
                            <td>
                              <Select v-model="selectedAnswers[rowQuestion.id]"
                                :options="referenceOptions[element.question.id]" filter optionLabel="label"
                                optionValue="value" placeholder="Выберите значение"
                                @change="filterReferenceDynamicDataByKey(element.question.id, rowQuestion.id)" :disabled="disabledDropdowns[rowQuestion.id]"  
                                :virtualScrollerOptions="{ itemSize: 36 }"
                                :loading="!referenceOptions[element.question.id] || referenceOptions[element.question.id].length === 0"
                                />
                            </td>
                            <td v-for="answer in element.question.answers" :key="answer.id">
                              <!-- Логика для обычных типов гридов -->
                              <div v-if="element.question.grid_type === 'SINGLE'" class="text-center">
                                <RadioButton :inputId="`grid_${rowQuestion.id}_${answer.id}`" :value="answer.id"
                                  v-model="selectedAnswers[rowQuestion.id]" />
                              </div>
                              <div v-else-if="element.question.grid_type === 'MULTIPLE'" class="text-center">
                                <Checkbox :inputId="`grid_${rowQuestion.id}_${answer.id}`" :value="answer.id"
                                  v-model="selectedAnswers[rowQuestion.id]" />
                              </div>
                              <div v-else-if="element.question.grid_type === 'NUM'">
                                <InputNumber v-model="selectedAnswers[rowQuestion.id]" :min="answer.min_value"
                                  :max="answer.max_value" mode="decimal" showButtons buttonLayout="horizontal"
                                  />
                              </div>
                              <div v-else-if="element.question.grid_type === 'SCALE'">
                                
                                <ScaleTemplate v-model="selectedAnswers[rowQuestion.id]" :stars="answer.max_value" />
                              </div>
                              <!-- Логика для Custom гридов -->
                              <template v-else-if="element.question.grid_type === 'CUSTOM'">
                                <div v-if="answer.answer_type === 'REFERENCE' && !element.question.has_dynamic_rows">
                                  <Select v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                    :options="referenceOptions[element.question.id + '_' + answer.id]" filter
                                    optionLabel="label" optionValue="value" placeholder="Выберите значение"
                                    :virtualScrollerOptions="{ itemSize: 36 }"
                                    :loading="!referenceOptions[element.question.id + '_' + answer.id] || referenceOptions[element.question.id + '_' + answer.id].length === 0"
                                    />
                                </div>
                                <div v-if="answer.answer_type === 'REFERENCE' && element.question.has_dynamic_rows">
                                  <Select v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                    :options="referenceOptions[element.question.id + '_' + rowQuestion.id + '_' + answer.id]"
                                    filter optionLabel="label" optionValue="value" placeholder="Выберите значение"
                                    @change="filterReferenceDataByColumn(element.question.id, rowQuestion.id, answer.id)" :disabled="disabledDropdowns[rowQuestion.id + '_' + answer.id]"  
                                    :virtualScrollerOptions="{ itemSize: 36 }"
                                    :loading="!referenceOptions[element.question.id + '_' + rowQuestion.id + '_' + answer.id] || referenceOptions[element.question.id + '_' + rowQuestion.id + '_' + answer.id].length === 0"
                                    />
                                </div>
                                <div v-else-if="answer.answer_type === 'NUM'">
                                  <InputNumber v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                    :min="answer.min_value" :max="answer.max_value" mode="decimal" showButtons
                                    buttonLayout="horizontal"  />
                                </div>
                                <div v-else-if="answer.answer_type === 'SCALE'">
                                  <ScaleTemplate v-model="selectedAnswers[rowQuestion.id + '_' + answer.id]"
                                    :stars="answer.max_value" />
                                </div>
                              </template>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                      <Button
                        v-if="element.question.has_dynamic_rows && element.question.visibleRows < element.question.child_questions.length"
                        @click="showMoreRows(element.question)" text>+ добавить ряд</Button>
                    </table>
                  </div>

                  <div v-if="element.question.question_type === 'REFERENCE'">
                    <div class="flex items-center mb-2" v-for="answer in element.question.answers" :key="answer.id">
                      <div v-if="!answer.is_none && !answer.is_open">
                      <p v-if="answer.title" class="m-0">{{ answer.title }}</p>
                      <Select  v-model="selectedAnswers[element.question.id + '_' + answer.id]"
                        :options="referenceOptions[element.question.id + '_' + answer.id]" filter optionLabel="label"
                        optionValue="value" placeholder="Выберите значение"
                        @change="filterReferenceDataByKey(element.question.id, answer.id)"
                        :disabled="isNoneSelected(element.question.id) === true"
                        :virtualScrollerOptions="{ itemSize: 36 }"
                        :loading="!referenceOptions[element.question.id + '_' + answer.id] || referenceOptions[element.question.id + '_' + answer.id].length === 0"
                        />
                      </div>
                      <div v-if="answer.is_none">
                        <Checkbox :inputId="`answer_${answer.id}`" :value="answer.title"
                          v-model="selectedAnswers[element.question.id + '_' + answer.id]"
                          @change="handleNoneAnswerChange(element.question.id, answer.id)"
                          :checked="isNoneSelected(element.question.id)" />
                        <label :for="`answer_${answer.id}`" class="ml-2">{{ answer.title }}</label>
                      </div>
                      <div v-if="answer.is_open">
                        <p class="m-0">{{ answer.title }}</p>
                        <InputText v-model="selectedAnswers[element.question.id + '_' + answer.id]" placeholder="Введите ответ" class="mt-0" />
                      </div>
                    </div>
                  </div>
                  <div v-if="element.question.question_type === 'SINGLE'">
                    <div class="flex items-center mb-2" v-for="answer in element.question.answers" :key="answer.id">
                      <div v-if="answer.is_none">
                        <RadioButton :inputId="`answer_${answer.id}`" :value="answer.id"
                          v-model="selectedAnswers[element.question.id]" />
                        <label :for="`answer_${answer.id}`" class="ml-2">{{ answer.title }}</label>
                      </div>
                      <div v-else>
                        <RadioButton :inputId="`answer_${answer.id}`" :value="answer.id"
                          v-model="selectedAnswers[element.question.id]" />
                        <label :for="`answer_${answer.id}`" class="ml-2">{{ answer.title }}</label>
                      </div>
                    </div>
                    <div
                      v-if="element.question.answers.some(answer => answer.is_open) && selectedAnswers[element.question.id] === element.question.answers.find(answer => answer.is_open)?.id">
                      <InputText v-model="openAnswerText[element.question.id]" placeholder="Введите ответ" class="mt-1"
                        @input="updateOpenAnswerText(element.question.id, $event.target.value)" />
                    </div>
                  </div>
                  <div v-if="element.question.question_type === 'MULTIPLE'">
                    <div v-for="answer in element.question.answers" :key="answer.id" class="flex items-center mb-2">
                      <div v-if="answer.is_none">
                        <Checkbox :inputId="`answer_${answer.id}`" :value="answer.id"
                          v-model="selectedAnswers[element.question.id]"
                          @change="handleNoneAnswerChange(element.question.id, answer.id)"
                          :checked="isNoneSelected(element.question.id)" />
                        <label :for="`answer_${answer.id}`" class="ml-2">{{ answer.title }}</label>
                      </div>
                      <div v-else>
                        <Checkbox :inputId="`answer_${answer.id}`" :value="answer.id"
                          :disabled="isNoneSelected(element.question.id)"
                          v-model="selectedAnswers[element.question.id]" />
                        <label :for="`answer_${answer.id}`" class="ml-2">{{ answer.title }}</label>
                      </div>
                    </div>
                    <div
                      v-if="element.question.answers.some(answer => answer.is_open) && Array.isArray(selectedAnswers[element.question.id]) && selectedAnswers[element.question.id].includes(element.question.answers.find(answer => answer.is_open)?.id)">
                      <InputText v-model="openAnswerText[element.question.id]" placeholder="Введите ответ"
                        :disabled="isNoneSelected(element.question.id)" class="mt-1"
                        @input="updateOpenAnswerText(element.question.id, $event.target.value)" />
                    </div>
                  </div>
                  <div v-if="element.question.question_type === 'NUM' || element.question.question_type === 'SCALE'"
                    class="num-block">
                    <div v-for="answer in element.question.answers" :key="answer.id" class="flex items-center mb-2">
                      <div v-if="answer.is_none" class="flex items-center mb-2">
                        <RadioButton :inputId="`none_${answer.id}`" :value="0"
                          v-model="selectedAnswers[element.question.id]"
                          @change="handleNoneAnswerChange(element.question.id, element.question.answers[0]?.id)"
                          :checked="isNoneSelected(element.question.id)" />
                        <label :for="`none_${answer.id}`" class="ml-2">{{ answer.title }}</label>
                      </div>
                      <div v-if="answer.is_open" class="flex items-center mb-2">
                        <RadioButton :inputId="`none_${answer.id}`" :value="answer.id"
                          v-model="selectedAnswers[element.question.id]" />
                        <label :for="`none_${answer.id}`" class="ml-2">{{ answer.title }}</label>
                      </div>
                      <div v-if="element.question.question_type === 'NUM' && !answer.is_none && !answer.is_open">
                        <InputNumber v-model="selectedAnswers[element.question.id]" :min="answer.min_value"
                          :max="answer.max_value"
                          :disabled="isNoneSelected(element.question.id)" />
                      </div>
                      <div v-if="element.question.question_type === 'SCALE' && !answer.is_none && !answer.is_open">
                        <ScaleTemplate v-model="selectedAnswers[element.question.id]" :stars="answer.max_value" />
                      </div>
                      <div
                        v-if="answer.is_open && (selectedAnswers[element.question.id] || openAnswerText[element.question.id])">
                        <InputText v-model="openAnswerText[element.question.id]" placeholder="Введите ответ"
                          class="mt-1" @input="updateOpenAnswerText(element.question.id, $event.target.value)" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isSurveyCompleted" class="survey-completion">
              <p v-if="completionText">{{ completionText }}</p>
              <p v-else>Вы уже завершили опрос. Благодарим за прохождение</p>
              <Button @click="finishCompletedSurvey" label="Закончить" />
            </div>
            <div class="navigation-buttons" v-if="!isSurveyCompleted">
              <Button v-if="currentScreenIndex < screens.length - 1 && !savingState" @click="nextScreen" label="Следующий" />
              <Button v-if="currentScreenIndex >= screens.length - 1 && !savingState"  @click="finishSurvey" label="Завершить" />
              <Button v-if="savingState" label="Сохранение..." severity="secondary" disabled />
            </div>
          </div>
        </div>
        <div v-else>
          <p v-if="isSurveyLoaded">Идёт загрузка данных опроса</p>
          <p v-else-if="isProgressLoaded">Идёт загрузка прогресса</p>
          <p v-else-if="isReferencesLoaded">Идёт загрузка справочников</p>
          <p v-else>Идёт загрузка</p>
          <ProgressSpinner style="width: 50px; height: 50px" strokeWidth="8" fill="transparent" />
        </div>
      </div>
    </div>
    <div class="col-4" v-if="showDebugPanel">
      <div class="debug-panel">
        <h3>Панель отладки</h3>
        <p><strong>Опросник студента:</strong></p>
        <pre>{{ allQuestionsAndAnswers }}</pre>
        <p><strong>Скрытые вопроса на текущем экране:</strong></p>
        <pre>{{ hiddenQuestions }}</pre>
        <p><strong>Правила:</strong></p>
        <pre>{{ formattedRules }}</pre>
        <p><strong>Неотвеченные вопросы:</strong></p>
        <pre>{{ Array.from(unansweredQuestions) }}</pre>
        <p><strong>Информация текущего экрана:</strong></p>
        <pre>{{ currentScreen }}</pre>
        <p><strong>Что приходит из БАзы</strong></p>
        <p>{{ savedData.allQuestionsAndAnswers }}</p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import Button from 'primevue/button';
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Select from 'primevue/select';
import ProgressSpinner from 'primevue/progressspinner';

import ScaleTemplate from '../../components/ScaleTemplate.vue'
import { getPublicSurvey, saveSurveyData, getSurveyProgress, getSurveyReferences } from '../../services/apiService';
const survey = ref({});
const screens = ref([]);
const currentScreenIndex = ref(0);
const route = useRoute();
const courseId = route.query.courseId;
const surveyId = ref(null)
const moduleId = route.query.moduleId;
const studentId = route.query.studentId;
const errorMessage = ref(false)
const isCheck = route.query.isCheck;
const isSurveyCompleted = ref(false);
const completionText = ref('');
const openAnswerText = ref({});
const isDataLoaded = ref(false);
const isSurveyLoaded = ref(false);
const isProgressLoaded = ref(false);
const isReferencesLoaded = ref(false);
const savingState = ref(false)

const shouldCompleteSurvey = ref(false); // Если правило завершения опроса сработает
// Проверяем корректность параметров
const isValidParams = computed(() => {
  return courseId && moduleId && studentId;
});
const showDebugPanel = computed(() => isCheck !== undefined);
const currentScreen = computed(() => screens.value[currentScreenIndex.value]);
const currentScreenId = ref(null)
const selectedAnswers = ref({});
const unansweredQuestions = ref(new Set());
const filteredElements = computed(() => {
  if (!currentScreen.value) return [];
  const sortedElements = currentScreen.value.elements.slice().sort((a, b) => (a.order || 0) - (b.order || 0));
  return filterElementsByRules(sortedElements);
});
const referenceOptions = ref({});

const referenceData = ref({});
const savedData = ref([])
const allOfThem = ref([])

const getStudentSurveyProgress = async () => {
  isProgressLoaded.value = true
  try {
    const response = await getSurveyProgress(surveyId.value, studentId);
    const progressData = response;
    if (progressData) {
      // savedData.value = progressData.allQuestionsAndAnswers;

      // Обновляем состояние текущего экрана и завершенности опроса
      // HARDCODE
      if (progressData.currentScreenId === 67 || progressData.currentScreenId === 51 ||  progressData.currentScreenId === 52) {
        currentScreenId.value = 50;
      } else if (progressData.currentScreenId === 97 || progressData.currentScreenId === 93 ||  progressData.currentScreenId === 94) {
        currentScreenId.value = 91;
      } else if (progressData.currentScreenId === 125 || progressData.currentScreenId === 121 ||  progressData.currentScreenId === 122) {
        currentScreenId.value = 119;
      } else if (progressData.currentScreenId === 59 || progressData.currentScreenId === 60 ||  progressData.currentScreenId === 61 ||  progressData.currentScreenId === 38 ||  progressData.currentScreenId === 39 ||  progressData.currentScreenId === 40 ||  progressData.currentScreenId === 62 ||  progressData.currentScreenId === 63) {
        currentScreenId.value = 57;
      } else if (progressData.currentScreenId === 145 ||  progressData.currentScreenId === 146 ||  progressData.currentScreenId === 147 ||  progressData.currentScreenId === 137 ||  progressData.currentScreenId === 138 ||  progressData.currentScreenId === 139 ||  progressData.currentScreenId === 148 ||  progressData.currentScreenId ===  149) {
        currentScreenId.value = 143;
      } else if (progressData.currentScreenId === 65) {
        currentScreenId.value = 64;
      } else if (progressData.currentScreenId === 151) {
        currentScreenId.value = 150;
      }
      else {
        currentScreenId.value = progressData.currentScreenId;
      }
      // HARDCODE
      isSurveyCompleted.value = progressData.isSurveyCompleted;
    }
    isProgressLoaded.value = false;
  } catch (error) {
    console.error('Error fetching student survey progress:', error);
    isProgressLoaded.value = false;
  }
};


const fetchSurveyData = async () => {
  try {
    console.time("Total Fetch Time");
    isSurveyLoaded.value = true;

    console.time("Survey Data Fetch");
    const response = await getPublicSurvey(courseId, moduleId);
    console.timeEnd("Survey Data Fetch");

    isSurveyLoaded.value = false;
    survey.value = response.data.survey;
    screens.value = response.data.screens;
    surveyId.value = survey.value.id;

    console.time("Student Progress Fetch");
    await getStudentSurveyProgress();
    console.timeEnd("Student Progress Fetch");

    if (currentScreenId.value) {
      const index = screens.value.findIndex(screen => screen.id === currentScreenId.value);
      currentScreenIndex.value = index !== -1 ? index : 0;
    } else {
      currentScreenIndex.value = 0;
    }

    isDataLoaded.value = true;

    console.time("Processing Screens");
    screens.value.forEach(screen => {
      screen.elements.forEach(element => {
        if (element.element_type === 'QUESTION') {
          element.question.answers = sortByOrder(element.question.answers);
          if (element.question.question_type !== 'GRID' && element.question.rotation) {
            element.question.answers = shuffledAnswers(element.question.answers);
          }
          if (element.question.question_type === 'GRID') {
            if (element.question.rotation) {
              element.question.child_questions = shuffleChildQuestions(element.question.child_questions);
            }
            if (element.question.has_dynamic_rows) {
              element.question.visibleRows = element.question.min_rows;
              element.question.child_questions = Array(element.question.max_rows).fill(null).map((_, index) => ({
                id: `${element.question.id}_${index + 1}`,
                title: `Строка ${index + 1}`,
                question_type: 'DYNAMIC',
              }));
            }
          }
        }
      });
    });
    console.timeEnd("Processing Screens");


    const referenceFiles = new Set();
    screens.value.forEach(screen => {
      screen.elements.forEach(element => {
        if (element.element_type === 'QUESTION') {
          if (element.question.reference_file) {
            referenceFiles.add(element.question.reference_file);
          }
          element.question.answers.forEach(answer => {
            if (answer.reference_file) {
              referenceFiles.add(answer.reference_file);
            }
          });
        }
      });
    });

    const referenceFilesArray = Array.from(referenceFiles);
    console.time("Reference Files Fetch");
    await getReferences(surveyId.value, referenceFilesArray);
    console.timeEnd("Reference Files Fetch");



    console.time("Loading References for Questions");
    for (const screen of screens.value) {
      for (const element of screen.elements) {
        if (element.element_type === 'QUESTION' && element.question.question_type === 'REFERENCE') {
          for (const answer of element.question.answers) {
            const referenceFileId = answer.reference_file;
            const columnName = answer.reference_column;
            if (referenceFileId && columnName) {
              await loadReferenceData(`${element.question.id}_${answer.id}`, referenceFileId, columnName);
            }
          }
        } else if (element.element_type === 'QUESTION' && element.question.question_type === 'GRID') {
          for (const answer of element.question.answers || []) {
            if (answer.answer_type === 'REFERENCE') {
              const referenceFileId = answer.reference_file;
              const columnName = answer.reference_column;
              if (referenceFileId && columnName) {
                if (element.question.grid_type === 'CUSTOM' && element.question.has_dynamic_rows) {
                  for (const childRow of element.question.child_questions) {
                    await loadReferenceData((element.question.id + '_' + childRow.id + '_' + answer.id), referenceFileId, columnName);
                  }
                } else {
                  await loadReferenceData((element.question.id + '_' + answer.id), referenceFileId, columnName);
                }
              }
            }
            if (element.question.has_dynamic_rows) {
              const referenceFileId = element.question.reference_file;
              const columnName = element.question.reference_column;
              if (referenceFileId && columnName) {
                await loadReferenceData((element.question.id), referenceFileId, columnName);
              }
            }
          }
        }
      }
    }
    console.timeEnd("Loading References for Questions");

    console.time("Hardcoded References Load");
    await loadReferenceData(9999, 4, 17);
    await loadReferenceData(10008, 4, 17);
    await loadReferenceData(10017, 4, 17);
    await loadReferenceData(10026, 4, 17);
    await loadReferenceData(10035, 4, 17);
    await loadReferenceData(10044, 4, 17);
    await loadReferenceData(88880, 4, 19);
    await loadReferenceData(88889, 4, 19);
    await loadReferenceData(88898, 4, 19);
    await loadReferenceData(88907, 4, 19);
    await loadReferenceData(88916, 4, 19);
    await loadReferenceData(88925, 4, 19);
    console.timeEnd("Hardcoded References Load");

    initializeRandomSymptoms();
    console.timeEnd("Total Fetch Time");
  } catch (error) {
    console.error('Error fetching survey:', error);
    errorMessage.value = true;
  }
};


const getReferences = async (surveyId, referenceFilesArray) => {
  isReferencesLoaded.value = true;
  try {
    // Создаем объект для хранения результатов
    const results = {};

    // Создаем и выполняем запросы по каждому reference_file
    for (const referenceFileId of referenceFilesArray) {

      const response = await getSurveyReferences(surveyId, referenceFileId);
      results[referenceFileId] = response.data; // Сохраняем данные по каждому reference_file
    }
    referenceData.value = results;
    isReferencesLoaded.value = false;
  } catch (error) {
    console.error("Ошибка при получении данных справочников:", error);
  }
};
const referenceCache = new Map();

const loadReferenceData = async (sku, referenceFileId, columnNameId, filterColumn, filterKey) => {
  try {
    // Формируем ключ для кэша с учетом фильтров
    const cacheKey = `${referenceFileId}_${columnNameId}_${filterColumn || ''}_${filterKey || ''}`;

    // Проверяем, есть ли уже кэшированные данные для этой комбинации
    if (referenceCache.has(cacheKey)) {
      referenceOptions.value[sku] = referenceCache.get(cacheKey);
      return;
    }

    // Получаем данные из referenceData по referenceFileId
    const reference = referenceData.value[referenceFileId];
    if (!reference) {
      console.error(`No data found for referenceFileId ${referenceFileId}`);
      return;
    }

    // Находим данные для нужной колонки по её ID
    const columnData = reference.data.find(col => col.column_id === columnNameId);
    if (columnData) {
      const columnEntries = Object.entries(columnData.data);

      let filteredData;
      if (filterKey && filterColumn) {
        // Находим данные для колонки по filterColumn
        const filterColumnData = reference.data.find(col => col.column_id === filterColumn);
        if (filterColumnData) {
          // Фильтруем ключи в filterColumnData по filterKey
          const filterColumnEntries = Object.entries(filterColumnData.data);
          const filteredKeys = filterColumnEntries
          // eslint-disable-next-line
            .filter(([key, value]) => value.includes(filterKey))
            .map(([key]) => key);

          // Отфильтровываем columnEntries по отфильтрованным ключам
          filteredData = columnEntries
            .filter(([key]) => filteredKeys.includes(key))
            // eslint-disable-next-line
            .map(([key, value]) => value);
        } else {
          console.error(`Filter column data not found for filterColumn ${filterColumn}`);
          filteredData = [];
        }
      } else {
        // Если filterKey не указан, используем все данные
        // eslint-disable-next-line
        filteredData = columnEntries.map(([key, value]) => value);
      }

      // Удаляем повторяющиеся значения и создаем массив объектов с уникальными значениями
      const uniqueValues = [...new Set(filteredData)].sort();
      const options = uniqueValues.map(value => ({
        label: value,
        value: value,
      }));

      // Сохраняем в кэш данные для текущей комбинации referenceFileId, columnNameId, filterColumn, filterKey
      referenceCache.set(cacheKey, options);

      // Обновляем referenceOptions с уникальными данными
      referenceOptions.value[sku] = options;
    } else {
      console.error(`Column data not found for columnNameId ${columnNameId}`);
    }
  } catch (error) {
    console.error(`Error loading reference data for sku ${sku}:`, error);
  }
};





// Функция для оценки условий с учетом логического оператора внутри одного правила
const evaluateConditionsForRule = (conditions, operator) => {
  if (operator === 'AND') {
    return conditions.every(condition => {
      // HARDCODE!
      let answer = selectedAnswers.value[condition.question];
      console.log("condition.question", condition.question)
      if (condition.question == 64) {
        answer = selectedAnswers.value["64_209"];
      } else if (condition.question == 676) {
        answer = selectedAnswers.value["676_976"];
      }
      // HARDCIDE
      const answerText = answer ? (Array.isArray(answer) ? answer.map(id => getAnswerTitle(condition.question, id)).join(', ') : getAnswerTitle(condition.question, answer)) : '';
      const answerNumber = parseFloat(answer);
      const conditionValueNumber = parseFloat(condition.value);
      if (condition.condition_type === 'SET') {
        // Сравниваем по answer_sku
        const result = answer && (Array.isArray(answer) ? answer.map(id => getAnswerSku(condition.question, id)).includes(condition.answer_sku) : getAnswerSku(condition.question, answer) === condition.answer_sku);
        return result;
      }
      if (condition.condition_type === 'CONTAINS') {
        const lowerAnswerText = answerText.toLowerCase();
        const lowerConditionValue = condition.value.toLowerCase();
        const result = lowerAnswerText.includes(lowerConditionValue);
        return result;
      }
      switch (condition.condition_type) {
        case 'EXISTS':
          // eslint-disable-next-line
          const exists = answer !== undefined && answer !== null;
          return exists;
        case 'LESS_THAN':
          if (!isNaN(answerNumber) && !isNaN(conditionValueNumber)) {
            const lessThan = answerNumber < conditionValueNumber;
            return lessThan;
          }
          return false;
        case 'GREATER_THAN':
          if (!isNaN(answerNumber) && !isNaN(conditionValueNumber)) {
            const greaterThan = answerNumber > conditionValueNumber;
            return greaterThan;
          }
          return false;
        case 'EQUALS':
          if (answerNumber === conditionValueNumber || answer === condition.value) {
            return true;
          }
          return false;
        case 'NOT_EQUALS':
          // eslint-disable-next-line
          const answerString = answer ? answer.toString() : ''; // Приведение к строке
          // eslint-disable-next-line
          const conditionString = condition.answer_sku ? condition.answer_sku.toString() : '';
          if (answerString !== conditionString) {
            return true;
          }
          return false;
        default:
          return false;
      }
    });
  } else if (operator === 'OR') {
    return conditions.some(condition => {
      let answer = selectedAnswers.value[condition.question];
      console.log("condition.question", condition.question)
      if (condition.question == 64) {
        answer = selectedAnswers.value["64_209"];
      } else if (condition.question == 676) {
        answer = selectedAnswers.value["676_976"];
      }  
      // const answerText = answer ? (Array.isArray(answer) ? answer.map(id => getAnswerTitle(condition.question, id)).join(', ') : getAnswerTitle(condition.question, answer)) : '';
      // Преобразуем значение и ответ в числа, если это возможно
      const answerNumber = parseFloat(answer);
      const conditionValueNumber = parseFloat(condition.value);
      if (condition.condition_type === 'SET') {
        const result = answer && (Array.isArray(answer) ? answer.map(id => getAnswerSku(condition.question, id)).includes(condition.answer_sku) : getAnswerSku(condition.question, answer) === condition.answer_sku);
        return result;
      }
      if (condition.condition_type === 'CONTAINS') {
        const lowerAnswerText = answer ? answer.toLowerCase() : ''; // Проверяем, что answer существует
        const lowerConditionValue = condition.value ? condition.value.toLowerCase() : ''; // Проверяем, что condition.value существует
        const result = lowerAnswerText.includes(lowerConditionValue);
        return result;
      }
      switch (condition.condition_type) {
        case 'EXISTS':
          // eslint-disable-next-line
          const exists = answer !== undefined && answer !== null;
          return exists;
        case 'LESS_THAN':
          if (!isNaN(answerNumber) && !isNaN(conditionValueNumber)) {
            const lessThan = answerNumber < conditionValueNumber;
            return lessThan;
          }
          return false;
        case 'GREATER_THAN':
          if (!isNaN(answerNumber) && !isNaN(conditionValueNumber)) {
            const greaterThan = answerNumber > conditionValueNumber;
            return greaterThan;
          }
          return false;
        case 'EQUALS':
          if (answerNumber === conditionValueNumber || answer === condition.value) {
            return true;
          }
          return false;
        case 'NOT_EQUALS':
          if (answerNumber !== conditionValueNumber || answer !== condition.value) {
            return true;
          }
          return false;
        default:
          return false;
      }
    });
  } else {
    return false;
  }
};
// Функция для получения заголовка ответа по его ID
const getAnswerTitle = (questionId, answerId) => {
  const question = screens.value.flatMap(screen => screen.elements)
    .find(element => element.element_type === 'QUESTION' && element.question.id === questionId);
  const answer = question ? question.question.answers.find(ans => ans.id === answerId) : null;
  return answer ? answer.title : '';
};
// Функция для получения answer_sku по questionId и answerId
const getAnswerSku = (questionId, answerId) => {
  const question = screens.value.flatMap(screen => screen.elements)
    .find(element => element.element_type === 'QUESTION' && element.question.id === questionId);
  const answer = question ? question.question.answers.find(ans => ans.id === answerId) : null;
  return answer ? answer.answer_sku : '';
};
// Функция фильтрации элементов на основе правил
const filterElementsByRules = (elements) => {
  return elements.filter(element => {
    if (element.element_type === 'QUESTION' || element.element_type === 'TEXT') {
      const rules = element.rules || [];
      console.log("rules", rules)
      // Проверяем правила для показа или скрытия
      const isElementVisible = rules.length === 0 || rules.some(rule => {
        if (rule.rule_type === 'SHOW' || rule.rule_type === 'HIDE') {
          const isConditionMet = evaluateConditionsForRule(rule.conditions, rule.operator);
          console.log("isConditionMet", isConditionMet)
          if (rule.rule_type === 'SHOW' && !isConditionMet) {
            return false; // Элемент не показывается
          }
          if (rule.rule_type === 'HIDE' && isConditionMet) {
            if (rule.answer_to_set) {
              // Устанавливаем значение ответа для скрытого вопроса
              const questionId = element.question.id;
              if (element.element_type === 'QUESTION' && element.question.question_type === 'MULTIPLE') {
                selectedAnswers.value[questionId] = [rule.answer_to_set];
              } else {
                selectedAnswers.value[questionId] = rule.answer_to_set;
              }
            }
            return false; // Элемент скрыт
          }
        }
        // Проверяем правила завершения опроса
        if (rule.rule_type === 'COMPLETE') {
          const isConditionMet = evaluateConditionsForRule(rule.conditions, rule.operator);
          if (isConditionMet) {
            shouldCompleteSurvey.value = true; // Устанавливаем флаг необходимости завершения
            completionText.value = rule.completion_text; // Устанавливаем текст завершения
          }
        }
        return true; // Оставляем элемент видимым, если ни одно правило не применимо
      });


      return isElementVisible;
    }
    return true; // Оставляем элементы без правил видимыми
  });
};
// Функция проверки заполненности полей
const validateScreen = () => {
  unansweredQuestions.value.clear();
  const elements = filteredElements.value;
  elements.forEach(element => {
    if (element.element_type === 'QUESTION') {
      const questionId = element.question.id;
      const questionType = element.question.question_type;
      // Проверка для вопросов типа SINGLE и REFERENCE
      if (questionType === 'SINGLE' || questionType === 'REFERENCE') {
        if (element.question.require_answers) {
          // Если вопрос не обязателен, пропускаем проверку
          return;
        }
        let isAnswered = true;

        if (questionType === 'REFERENCE') {
        const answers = element.question.answers;

        // Проверяем, заполнен ли открытый ответ
        const isOpenAnswerFilled = answers.some(answer =>
          answer.is_open && selectedAnswers.value[`${questionId}_${answer.id}`]
        );
        const isNoneFilled = answers.some(answer =>
          answer.is_none && selectedAnswers.value[`${questionId}_${answer.id}`]
        );

        if (isOpenAnswerFilled || isNoneFilled) {
          // Если открыт ответ заполнен, пропускаем проверку
          return;
        }

        // Проверяем все селекты
        answers.forEach(answer => {
          if (!answer.is_open) {
            const answerKey = `${questionId}_${answer.id}`;
            if (!selectedAnswers.value[answerKey]) {
              isAnswered = false;
            }
          }
        });
      } else {
          if (selectedAnswers.value[questionId] == null) {
            isAnswered = false;
          }
        }

        if (!isAnswered) {
          unansweredQuestions.value.add(questionId);
        } else {
          // Проверка текста открытого ответа только если ответ выбран и является открытым
          const isOpenAnswerSelected = element.question.answers.some(answer =>
            answer.is_open && answer.id === selectedAnswers.value[questionId]
          );
          if (isOpenAnswerSelected && !openAnswerText.value[questionId]) {
            unansweredQuestions.value.add(questionId);
          }
        }
      }
      // Проверка для вопросов типа MULTIPLE
      if (questionType === 'MULTIPLE') {
        const selectedAnswerIds = selectedAnswers.value[questionId] || [];
        if (selectedAnswerIds.length === 0) {
          unansweredQuestions.value.add(questionId);
        } else {
          // Проверка текста открытого ответа только для выбранных открытых ответов
          const openAnswers = element.question.answers.filter(answer =>
            answer.is_open && selectedAnswerIds.includes(answer.id)
          );
          if (openAnswers.length > 0 && !openAnswerText.value[questionId]) {
            unansweredQuestions.value.add(questionId);
          }
        }
      }
      // Проверка для вопросов типа NUM и SCALE
      if (questionType === 'NUM' || questionType === 'SCALE') {
        if (selectedAnswers.value[questionId] == null) {
          unansweredQuestions.value.add(questionId);
        } else {
          // Проверка текста открытого ответа только если выбран открытый ответ
          const isOpenAnswerSelected = element.question.answers.some(answer =>
            answer.is_open && answer.id === selectedAnswers.value[questionId]
          );
          if (isOpenAnswerSelected && !openAnswerText.value[questionId]) {
            unansweredQuestions.value.add(questionId);
          }
        }
      }
      // Проверка для вопросов типа GRID
      if (questionType === 'GRID' && element.question.grid_type != 'CUSTOM') {
        let isValid = true;
        // Проверяем каждую строку
        element.question.child_questions.forEach(rowQuestion => {
          const selectedAnswersForRow = selectedAnswers.value[rowQuestion.id] || [];
          // Если rowQuestion.is_open, проверяем только если title не пустой
          if (rowQuestion.is_open) {
            if (rowQuestion.title && selectedAnswersForRow.length === 0) {
              isValid = false;
              unansweredQuestions.value.add(rowQuestion.id);
            }
          } else {
            // Если rowQuestion не открытый, можно добавлять проверки по желанию
            // Например, можно проверять все, если это нужно
            if (selectedAnswersForRow.length === 0) {
              isValid = false;
              unansweredQuestions.value.add(rowQuestion.id);
            }
          }
        });
        // Проверяем, если в любом ряду нет ответа, добавляем родительский вопрос в непроходящие вопросы
        if (!isValid) {
          unansweredQuestions.value.add(questionId);
        }
      }
      // HARDCODE Q-33
      if (questionId === 225 || questionId === 358 || questionId === 482 || questionId === 701) {
        // let isValid = true;

        // Получаем все индексы рядов, где dropdownOne заполнен
        const filledRowIndices = Object.keys(dropdownValues.value)
          .filter(key => key.startsWith('dropdownOne_'))
          .map(key => parseInt(key.split('_')[1]))
          .filter(rowIndex => dropdownValues.value[`dropdownOne_${rowIndex}`]);

        // Если нет заполненных dropdownOne, добавляем вопрос в непроходящие
        if (filledRowIndices.length === 0) {
          unansweredQuestions.value.add(questionId);
        } /* else {
          // Для всех найденных rowIndex проверяем правильность данных
          filledRowIndices.forEach(rowIndex => {
            const startRow = Math.floor(rowIndex / 9) * 9;
            const endRow = startRow + 9;
            if (questionId === 225) {
              for (let i = startRow; i < endRow; i++) {
                const rowQuestionId = `225_${i + 1}`;
                const rowQuestion = element.question.child_questions.find(q => q.id === rowQuestionId);

                if (rowQuestion) {
                  let skipRowValidation = false;
                  // Проверяем ответы для текущего ряда
                  element.question.answers.forEach(answer => {
                    const answerKey = `${rowQuestionId}_${answer.id}`;
                    // Если ответ имеет id 379 и значение равно 0, пропускаем эту проверку
                    if (answer.id === 379 && selectedAnswers.value[answerKey] === 0) { 
                      skipRowValidation = true; // Устанавливаем флаг, чтобы пропустить добавление
                    }

                    // Если это не ответ с id 459, проверяем его наличие
                    if (answer.id !== 459 && !skipRowValidation && !selectedAnswers.value[answerKey]) {
                      isValid = false;
                      unansweredQuestions.value.add(rowQuestionId);
                    }
                  });

                  if (!skipRowValidation && !isValid) {
                    unansweredQuestions.value.add(questionId);
                  }
                }
              }
            } else if (questionId === 358) {
              for (let i = startRow; i < endRow; i++) {
                const rowQuestionId = `358_${i + 1}`;
                const rowQuestion = element.question.child_questions.find(q => q.id === rowQuestionId);

                if (rowQuestion) {
                  let skipRowValidation = false;
                  // Проверяем ответы для текущего ряда
                  element.question.answers.forEach(answer => {
                    const answerKey = `${rowQuestionId}_${answer.id}`;
                    // Если ответ имеет id 612 и значение равно 0, пропускаем эту проверку
                    if (answer.id === 612 && selectedAnswers.value[answerKey] === 0) { 
                      skipRowValidation = true; // Устанавливаем флаг, чтобы пропустить добавление
                    }

                    // Если это не ответ с id 610, проверяем его наличие
                    if (answer.id !== 610 && !skipRowValidation && !selectedAnswers.value[answerKey]) {
                      isValid = false;
                      unansweredQuestions.value.add(rowQuestionId);
                    }
                  });

                  if (!skipRowValidation && !isValid) {
                    unansweredQuestions.value.add(questionId);
                  }
                }
              }
            } else if (questionId === 482) {
              for (let i = startRow; i < endRow; i++) {
                const rowQuestionId = `482_${i + 1}`;
                const rowQuestion = element.question.child_questions.find(q => q.id === rowQuestionId);

                if (rowQuestion) {
                  let skipRowValidation = false;
                  // Проверяем ответы для текущего ряда
                  element.question.answers.forEach(answer => {
                    const answerKey = `${rowQuestionId}_${answer.id}`;
                    // Если ответ имеет id 782 и значение равно 0, пропускаем эту проверку
                    if (answer.id === 782 && selectedAnswers.value[answerKey] === 0) { 
                      skipRowValidation = true; // Устанавливаем флаг, чтобы пропустить добавление
                    }

                    // Если это не ответ с id 780, проверяем его наличие
                    if (answer.id !== 780 && !skipRowValidation && !selectedAnswers.value[answerKey]) {
                      isValid = false;
                      unansweredQuestions.value.add(rowQuestionId);
                    }
                  });

                  if (!skipRowValidation && !isValid) {
                    unansweredQuestions.value.add(questionId);
                  }
                }
              }
            }
          });
        } */
      }
      // Если это вопрос с id = 320 или 602, проверяем только главные ряды
      if (questionId === 320 || questionId === 602) {
          let isValid = ref(true);
          const mainRowIndices = [1, 5, 9, 13, 17, 21, 25, 29, 33, 37, 41, 45, 49, 53, 57]; // Главные ряды
          mainRowIndices.forEach(index => {
            let rowQuestionId = ref('')
            if (surveyId.value === 4) {
              rowQuestionId.value = `320_${index}`;
            } else if (surveyId.value === 8) {
              rowQuestionId.value = `602_${index}`;
            }
            // const selectedAnswersForRow = selectedAnswers.value[rowQuestionId.value] || [];
            // console.log("selectedAnswersForRow", selectedAnswersForRow)
              // Проверяем jndt
            element.question.answers.forEach(answer => {
              const answerKey = `${rowQuestionId.value}_${answer.id}`;
              if (!selectedAnswers.value[answerKey]) {
                isValid.value = false;
                unansweredQuestions.value.add(rowQuestionId.value);
              }
            });
          });
        }
      // Логика для GRID с динамическими рядами
      if (questionType === 'GRID' && element.question.grid_type === 'CUSTOM' && element.question.has_dynamic_rows && (questionId != 225 && questionId != 358 && questionId != 482 && questionId != 320 && questionId != 602 && questionId != 701)) {
        if (element.question.not_required) {
          // Если вопрос не обязателен, пропускаем проверку
          return;
        }

        let hasFilledDropdown = false; // Флаг для проверки наличия заполненных дропдаунов
        let isValid = true;


        // Сначала проверяем наличие хотя бы одного заполненного дропдауна
        element.question.child_questions.forEach(rowQuestion => {
          const selectedDropdownValue = selectedAnswers.value[rowQuestion.id];
          if (selectedDropdownValue) {
            hasFilledDropdown = true; // Если найден заполненный дропдаун, устанавливаем флаг в true
          }
        });

        if (!hasFilledDropdown) {
          // Если нет заполненных дропдаунов, добавляем ошибку к первому дропдауну
          unansweredQuestions.value.add(element.question.child_questions[0].id);
          return; // Выходим из проверки
        }

        // Проверяем остальные поля, если есть заполненные дропдауны
        element.question.child_questions.forEach(rowQuestion => {
          const selectedDropdownValue = selectedAnswers.value[rowQuestion.id];
          if (!selectedDropdownValue) {
            return; // Если дропдаун не заполнен, пропускаем проверку этой строки
          }

          // Проверяем другие поля в строке
          element.question.answers.forEach(answer => {
            const answerKey = `${rowQuestion.id}_${answer.id}`;
            if (!selectedAnswers.value[answerKey]) {
              isValid = false;
              unansweredQuestions.value.add(rowQuestion.id);
            }
          });
        });

        if (!isValid) {
          unansweredQuestions.value.add(questionId);
        }
      }

    }
  });
  return unansweredQuestions.value.size === 0;
};

const isQuestionAnswered = (questionId) => {
  return !unansweredQuestions.value.has(questionId);
};
const updateOpenAnswerText = (questionId, value) => {
  openAnswerText.value = { ...openAnswerText.value, [questionId]: value };
};

const isScreenVisible = (screen) => {
  // Фильтруем элементы экрана, чтобы определить, есть ли видимые элементы
  const visibleElements = filterElementsByRules(screen.elements);
  console.log("visibleElements", visibleElements.value)
  return visibleElements.length > 0;
};

const nextScreen = async () => {
  if (validateScreen()) {
    let currentScreen = screens.value[currentScreenIndex.value];

    if (shouldCompleteSurvey.value) {
      if (studentId != 1) {
        try {
          savingState.value = true
          await saveSurveyData({
            studentId,
            surveyId: surveyId.value,
            responses: allOfThem.value,
            currentScreenId: currentScreen.id,
            isSurveyCompleted: true
          });
          savingState.value = false
        } catch (error) {
          console.error('Error saving data:', error);
          savingState.value = false
          return; // Прекратить выполнение, если произошла ошибка
        }
        isSurveyCompleted.value = true; // Завершаем опрос
        shouldCompleteSurvey.value = false; // Сбрасываем флаг завершения
      }
    } else {
      if (currentScreenIndex.value < screens.value.length - 1) {
        let newCurrentScreen = screens.value[currentScreenIndex.value + 1];

        if (studentId != 1) {
          try {
            savingState.value = true
            await saveSurveyData({
              studentId,
              surveyId: surveyId.value,
              responses: allOfThem.value,
              currentScreenId: newCurrentScreen.id,
              isSurveyCompleted: false
            });
            savingState.value = false
          } catch (error) {
            console.error('Error saving data:', error);
            savingState.value = false
            return; // Прекратить выполнение, если произошла ошибка
          }
        }
        currentScreenIndex.value += 1;
        // Прокрутка страницы вверх с плавной анимацией
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });

        // Обновление контента на экране
        screens.value[currentScreenIndex.value].elements.forEach(element => {
          if (element.element_type === 'TEXT') {
            element.content = replaceQuestionSkusInText(element.content);
          }
          if (element.element_type === 'QUESTION') {
            element.question.title = replaceQuestionTitlesInText(element.question.title);
          }
        });
        console.log("nextScreen!", screens.value[currentScreenIndex.value])
        // Переход к следующему экрану, если текущий экран пуст
        if (!isScreenVisible(screens.value[currentScreenIndex.value])) {
          console.log("Пропускаем")
          nextScreen();
        }
      }
    }
  }
};

const finishSurvey = async () => {
  if (validateScreen()) {
    let currentScreen = screens.value[currentScreenIndex.value];
    if (studentId != 1) {
      try {
        savingState.value = true
        await saveSurveyData({
          studentId,
          surveyId: surveyId.value,
          responses: allOfThem.value,
          currentScreenId: currentScreen.id,
          isSurveyCompleted: true // Опрос завершён
        });
        savingState.value = false
        console.log("Data saved and survey is completed!");
        isSurveyCompleted.value = true;
        window.parent.postMessage({
          action: 'next',
        }, '*');
      } catch (error) {
        console.error('Error completing survey:', error);
        savingState.value = false
        return;
      }
    } else {
      isSurveyCompleted.value = true;
      console.log("Survey was completed by test User!")
    }
  }

};

const finishCompletedSurvey = async () => {
        console.log("finishCompletedSurvey!");
        window.parent.postMessage({
          action: 'next',
        }, '*');
};

const allQuestionsAndAnswers = computed(() => {
  if (!isDataLoaded.value) {
    return []; // Не возвращаем данные, пока они не загружены
  }
  const allQuestions = [];
  screens.value.forEach(screen => {
    screen.elements.forEach(element => {
      if (element.element_type === 'QUESTION') {
        const questionId = element.question.id;
        const questionTitle = element.question.title;
        const questionSku = element.question.question_sku;
        const questionType = element.question.question_type;
        let answerText = 'NULL';
        let answerId = 'NULL';

        // Обрабатываем ответы для основного вопроса
        if (questionType === 'SINGLE') {
          answerId = selectedAnswers.value[questionId] || 'NULL';
          const selectedAnswer = element.question.answers.find(ans => ans.id === answerId);
          answerText = selectedAnswer ? selectedAnswer.title : 'NULL';
          if (selectedAnswer && selectedAnswer.is_open) {
            answerText = openAnswerText.value[questionId] || answerText;
          }
        } else if (questionType === 'MULTIPLE') {
          const selectedAnswersForQuestion = selectedAnswers.value[questionId];
          let selectedAnswerIds = Array.isArray(selectedAnswersForQuestion)
            ? selectedAnswersForQuestion
            : (typeof selectedAnswersForQuestion === 'number'
              ? [selectedAnswersForQuestion]
              : []);
          answerId = selectedAnswerIds.length > 0 ? selectedAnswerIds.join(', ') : 'NULL';
          answerText = selectedAnswerIds.map(ansId => {
            const selectedAnswer = element.question.answers.find(ans => ans.id === ansId);
            if (selectedAnswer && selectedAnswer.is_open) {
              return openAnswerText.value[questionId] || selectedAnswer.title;
            }
            return selectedAnswer ? selectedAnswer.title : 'NULL';
          }).join(', ');
        } else if (questionType === 'NUM' || questionType === 'SCALE') {
          const isNoneSelected = element.question.answers.some(answer =>
            answer.is_none && selectedAnswers.value[questionId] === 0 // не знаю сработает ли
          );
          answerId = isNoneSelected ? 'NONE' : 'numeric';
          answerText = isNoneSelected ? element.question.answers.find(answer => answer.is_none).title : (selectedAnswers.value[questionId] != null ? selectedAnswers.value[questionId] : 'NULL');
        } else if (questionType === 'REFERENCE') {
          // Проверяем, если выбран вариант с `is_none`
          const isNoneSelectedForQuestion = element.question.answers.some(answer => answer.is_none && isNoneSelected(questionId));

          if (isNoneSelectedForQuestion) {
            // Если выбран вариант с `is_none`, находим его и устанавливаем его `answerText`
            const noneAnswer = element.question.answers.find(answer => answer.is_none);
            answerId = 'NONE'; // Или другой подходящий идентификатор
            answerText = noneAnswer ? noneAnswer.title : 'NULL';
          } else {
            // Если `is_none` не выбран, обрабатываем стандартный список ответов
            const selectedAnswerIds = element.question.answers.map(answer => {
              return selectedAnswers.value[`${questionId}_${answer.id}`] || 'NULL';
            }).join(', ');

            const selectedAnswerTexts = element.question.answers.map(answer => {
              const selectedValue = selectedAnswers.value[`${questionId}_${answer.id}`] || 'NULL';
              return selectedValue;
            }).join(', ');

            answerId = selectedAnswerIds;
            answerText = selectedAnswerTexts;
          }
        }

        const questionData = {
          questionId,
          questionSku,
          questionTitle,
          answerId,
          answerText
        };

        if (questionType === 'GRID') {
          questionData.childQuestions = [];
          const childQuestions = element.question.child_questions || []; // Получаем дочерние вопросы

          childQuestions.forEach(childQuestion => {
            const childQuestionId = childQuestion.id;
            // Проверяем, есть ли выбранное значение для дочернего вопроса
            let childQuestionTitle = childQuestion.title;
            if (element.question.has_dynamic_rows) {
              if (selectedAnswers.value[childQuestionId]) {
                childQuestionTitle = selectedAnswers.value[childQuestionId]
              }
            }
            //?? const childQuestionType = childQuestion.question_type;

            let childAnswerText = [];
            let childAnswerId = 'NULL';


            if (element.question.grid_type === 'CUSTOM' && element.question.answers.length > 0) {
              element.question.answers.forEach(column => {
                const columnAnswerId = selectedAnswers.value[`${childQuestionId}_${column.id}`] || 'NULL';
                const columnAnswerText = selectedAnswers.value[`${childQuestionId}_${column.id}`] || 'NULL';
                /* let columnAnswerText = columnAnswerId !== 'NULL'
                  ? (column.title || 'NULL')
                  : 'NULL';
                */
                childAnswerText.push({
                  columnId: column.id,
                  columnTitle: column.title,
                  answerId: columnAnswerId,
                  answerText: columnAnswerText
                });
              });
            } else {
              if (element.question.grid_type === 'SCALE') {
                childAnswerId = selectedAnswers.value[childQuestionId] || 'NULL';
                childAnswerText = childAnswerId !== 'NULL' ? childAnswerId : 'NULL';
              } else if (element.question.grid_type === 'MULTIPLE') {
                childAnswerText = Array.isArray(selectedAnswers.value[childQuestionId])
                  ? selectedAnswers.value[childQuestionId].map(ansId => {
                    const selectedAnswer = element.question.answers.find(ans => ans.id === ansId);
                    return selectedAnswer ? selectedAnswer.title : 'NULL';
                  }).join(', ')
                  : 'NULL';
              } else {
                childAnswerId = selectedAnswers.value[childQuestionId] || 'NULL';
                childAnswerText = childAnswerId !== 'NULL'
                  ? (element.question.answers.find(ans => ans.id === childAnswerId)?.title || 'NULL')
                  : 'NULL';
              }
            }
            questionData.childQuestions.push({
              questionId: childQuestionId,
              questionTitle: childQuestionTitle,
              answerId: childAnswerId,
              answerText: childAnswerText
            });
          });
        }
        allQuestions.push(questionData);
      }
    });
  });
  return allQuestions;
});


const handleNoneAnswerChange = (questionId, noneAnswerId) => {
  const questionType = getQuestionType(questionId);
  if (questionType === 'SINGLE') {
    if (selectedAnswers.value[questionId] === noneAnswerId) {
      // Если выбран is_none вариант, то сбрасываем выбор
      selectedAnswers.value[questionId] = null;
    } else {
      // Если is_none снят, то устанавливаем его выбор
      selectedAnswers.value[questionId] = noneAnswerId;
    }
  } else if (questionType === 'REFERENCE') {
    if (selectedAnswers.value[questionId] === noneAnswerId) {
      // Если выбран is_none вариант, то сбрасываем выбор
      selectedAnswers.value[questionId] = null;

    } else {
      // Если is_none снят, то устанавливаем его выбор
      selectedAnswers.value[questionId] = noneAnswerId;
      for (const key in selectedAnswers.value) {
        if (key.startsWith(`${questionId}_`) && key !== `${questionId}_${noneAnswerId}`) {
          selectedAnswers.value[key] = null;
        }
      }
    }
  } else if (questionType === 'MULTIPLE') {
    if (selectedAnswers.value[questionId]?.includes(noneAnswerId)) {
      // Если выбран is_none вариант, то снимаем выбор всех других вариантов
      selectedAnswers.value[questionId] = [noneAnswerId];
    } else {
      // Если is_none снят, то очищаем его выбор
      selectedAnswers.value[questionId] = selectedAnswers.value[questionId].filter(id => id !== noneAnswerId);
    }
  } else if (questionType === 'GRID') {
    if (selectedAnswers.value[questionId]?.includes(noneAnswerId)) {
      // Если выбран is_none вариант, то снимаем выбор всех других вариантов
      selectedAnswers.value[questionId] = [noneAnswerId];
    } else {
      // Если is_none снят, то очищаем его выбор
      if (Array.isArray(selectedAnswers.value[questionId])) {
        selectedAnswers.value[questionId] = selectedAnswers.value[questionId].filter(id => id !== noneAnswerId);
      }
    }
  }
};

const isNoneSelected = (questionId) => {
  const questionType = getQuestionType(questionId);
  if (questionType === 'SINGLE') {
    return selectedAnswers.value[questionId] && isNoneAnswer(questionId, selectedAnswers.value[questionId]);
  } else if (questionType === 'REFERENCE') {
    return selectedAnswers.value[questionId] && isNoneAnswer(questionId, selectedAnswers.value[questionId]);
  } else if (questionType === 'MULTIPLE') {
    const answers = Array.isArray(selectedAnswers.value[questionId]) ? selectedAnswers.value[questionId] : [];
    return answers.some(answerId => isNoneAnswer(questionId, answerId));
  } else if (questionType === 'GRID') {
    // Получаем ответы для каждой строки (дочернего вопроса) грида
    const rowAnswers = Array.isArray(selectedAnswers.value[questionId]) ? selectedAnswers.value[questionId] : [];
    return rowAnswers.some(answerId => isNoneAnswer(questionId, answerId));
  }
  return false;
};

const getQuestionType = (questionId) => {
  // Сначала ищем основной вопрос
  const question = screens.value.flatMap(screen => screen.elements)
    .find(element => element.element_type === 'QUESTION' && element.question.id === questionId);

  if (question) {
    return question.question.question_type;
  } else {
    // Если основной вопрос не найден, ищем среди дочерних вопросов
    const childQuestion = screens.value.flatMap(screen => screen.elements)
      .flatMap(element => element.element_type === 'QUESTION' ? element.question.child_questions : [])
      .find(child => child && child.id === questionId); // Проверка на null

    return childQuestion ? childQuestion.question_type : null;
  }
};

const isNoneAnswer = (questionId, answerId) => {
  // Сначала ищем основной вопрос
  const question = screens.value.flatMap(screen => screen.elements)
    .find(element => element.element_type === 'QUESTION' && element.question?.id === questionId);

  // Если нашли основной вопрос, проверяем его ответы
  if (question) {
    return question.question.answers?.some(answer => answer.id === answerId && answer.is_none) || false;
  } else {
    // Если основного вопроса нет, ищем среди дочерних вопросов
    const childQuestion = screens.value.flatMap(screen => screen.elements)
      .flatMap(element => element.element_type === 'QUESTION' ? element.question.child_questions || [] : [])
      .find(child => child.id === questionId);

    if (childQuestion) {
      // Найти родительский вопрос, к которому относится этот дочерний вопрос
      const parentQuestion = screens.value.flatMap(screen => screen.elements)
        .find(element => element.element_type === 'QUESTION' && element.question.child_questions?.some(child => child.id === questionId));
      // Если нашли родительский вопрос, проверяем его ответы
      return parentQuestion?.question.answers?.some(answer => answer.id === answerId && answer.is_none) || false;
    } else {
      return false;
    }
  }
};



const formattedRules = computed(() => {
  const allRules = [];
  screens.value.forEach(screen => {
    screen.elements.forEach(element => {
      const elementRules = element.rules || []; // Extract rules regardless of element type
      elementRules.forEach(rule => {
        allRules.push({
          elementId: element.id, // Add element ID for easier lookup later
          elementType: element.element_type, // Add element type
          ruleType: rule.rule_type,
          condition: rule.conditions.map(cond => ({
            question: cond.question,
            type: cond.condition_type,
            answer: cond.answer_sku || cond.value
          }))
        });
      });
    });
  });
  return allRules;
});

onMounted(fetchSurveyData);

const shuffleArray = (array) => {
  let currentIndex = array.length, randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
};
const shuffledAnswers = (answers) => {
  const fixedAnswers = answers.filter(answer => answer.is_rotatable);
  const nonFixedAnswers = answers.filter(answer => !answer.is_rotatable);
  const shuffledNonFixedAnswers = shuffleArray(nonFixedAnswers);
  const finalAnswers = [];
  let fixedIndex = 0, nonFixedIndex = 0;
  for (let i = 0; i < answers.length; i++) {
    if (fixedAnswers.some(answer => answer.order === i + 1)) {
      finalAnswers.push(fixedAnswers[fixedIndex++]);
    } else {
      finalAnswers.push(shuffledNonFixedAnswers[nonFixedIndex++]);
    }
  }
  return finalAnswers;
};

// Функция для перемешивания дочерних вопросов с учетом их rotatable свойства
const shuffleChildQuestions = (childQuestions) => {
  const fixedChildQuestions = childQuestions.filter(question => question.is_rotatable);
  const rotatableChildQuestions = childQuestions.filter(question => !question.is_rotatable);
  const shuffledRotatableChildQuestions = shuffleArray(rotatableChildQuestions);
  const finalChildQuestions = [];
  let fixedIndex = 0, shuffledIndex = 0;
  childQuestions.forEach((question) => {
    if (!question.is_rotatable) {
      finalChildQuestions.push(shuffledRotatableChildQuestions[shuffledIndex++]);
    } else {
      finalChildQuestions.push(fixedChildQuestions[fixedIndex++]);
    }
  });
  return finalChildQuestions;
};

const hiddenQuestions = computed(() => {
  if (!currentScreen.value) return [];
  const hiddenQuestions = [];
  const elements = filterElementsByRules(currentScreen.value.elements);
  currentScreen.value.elements.forEach(element => {
    if (element.element_type === 'QUESTION') {
      const questionId = element.question.id;
      const isVisible = elements.includes(element);
      if (!isVisible) {
        hiddenQuestions.push({
          questionId,
          questionTitle: element.question.title,
        });
      }
    }
  });
  return hiddenQuestions;
});

watch(hiddenQuestions, (newHiddenQuestions, oldHiddenQuestions) => {
  const newlyVisibleQuestions = oldHiddenQuestions.filter(oldQ => !newHiddenQuestions.some(newQ => newQ.questionId === oldQ.questionId));

  // Сбрасываем значения для вновь показанных вопросов
  newlyVisibleQuestions.forEach(question => {
    selectedAnswers.value[question.questionId] = "";
  });
});

const sortByOrder = (answers) => {
  return answers.slice().sort((a, b) => (a.order || 0) - (b.order || 0));
};

// Dynamic rows
const showMoreRows = (question) => {
  // HARDCODE Q-33
  if (question.id === 225 || question.id === 358 || question.id === 482 || question.id === 701) {
    // Добавляем 9 рядов или оставшиеся строки до конца массива child_questions
    question.visibleRows = Math.min(question.visibleRows + 9, question.child_questions.length);
    // HARDCODE Q-33
  } else {
    question.visibleRows = Math.min(question.visibleRows + 1, question.child_questions.length);
  }
};

// Replace dynamic text
const replaceQuestionSkusInText = (content) => {
  const skuRegex = /\[Q-(\d+)\]/g;

  return content.replace(skuRegex, (match, sku) => {
    // Найдите вопрос по question_sku
    const question = allQuestionsAndAnswers.value.find(q => q.questionSku === `Q-${sku}`);
    return question ? question.answerText : match;
  });
};
const replaceQuestionTitlesInText = (text) => {
  const skuRegex = /\[Q-(\d+)\]/g;

  return text.replace(skuRegex, (match, sku) => {
    // Найдите вопрос по question_sku
    const question = allQuestionsAndAnswers.value.find(q => q.questionSku === `Q-${sku}`);
    return question ? question.answerText : match;
  });
};

watch([isDataLoaded, allQuestionsAndAnswers], ([newIsDataLoaded, newAllQuestionsAndAnswers]) => {
  if (newIsDataLoaded) {
    // Формируем данные для сохранения
    allOfThem.value = newAllQuestionsAndAnswers.map(question => {
      // Формируем структуру ответа
      let childQuestions = [];
      if (question.childQuestions && question.childQuestions.length > 0) {
        childQuestions = question.childQuestions.map(childQuestion => {
          // Формируем ответы по колонкам для кастомных грида
          let columnAnswers = [];
          if (Array.isArray(childQuestion.answerText)) {
            columnAnswers = childQuestion.answerText.map(columnAnswer => ({
              columnId: columnAnswer.columnId,
              columnTitle: columnAnswer.columnTitle,
              answerId: columnAnswer.answerId,
              answerText: columnAnswer.answerText
            }));
          }
          return {
            questionId: childQuestion.questionId,
            questionTitle: childQuestion.questionTitle,
            answerId: childQuestion.answerId,
            answerText: childQuestion.answerText,
            columnAnswers: columnAnswers // Добавляем данные по колонкам
          };
        });
      }
      return {
        questionId: question.questionId,
        questionTitle: question.questionTitle,
        answerId: question.answerId,
        answerText: question.answerText,
        childQuestions: childQuestions
      };
    });

  }
});

const filterReferenceDataByKey = async (questionFilterId, answerId) => {
  // Пройдемся по всем экранам
  for (const screen of screens.value) {
    for (const element of screen.elements) {
      if (element.element_type === 'QUESTION' && element.question.question_type === 'REFERENCE') {
        for (const answer of element.question.answers) {
          if (answer.filter_answer_reference_id && answer.filter_answer_reference_id === answerId) {
            const referenceFileId = answer.reference_file;
            const columnName = answer.reference_column;
            const filter_answer_reference_id = answer.filter_answer_reference_id;
            if (referenceFileId && columnName && filter_answer_reference_id) {
              // Найти question.id по filter_answer_reference_id в screens.value
              let questionId;
              let filterColumn;
              for (const screenElement of screen.elements) {
                if (screenElement.element_type === 'QUESTION' &&
                  screenElement.question.question_type === 'REFERENCE') {
                  const matchingAnswer = screenElement.question.answers.find(ans => ans.id === filter_answer_reference_id);
                  if (matchingAnswer) {
                    questionId = screenElement.question.id;
                    filterColumn = matchingAnswer.reference_column
                    break;
                  }
                }
              }
              if (questionId) {
                // Найти значение answerText для questionId в allQuestionsAndAnswers
                const matchingQuestion = allQuestionsAndAnswers.value.find(question =>
                  question.questionId === questionId
                );
                if (matchingQuestion) {
                  const filterKey = selectedAnswers.value[questionFilterId + '_' + answerId];
                  // Запускаем loadReferenceData для фильтрации с использованием filterKey
                  await loadReferenceData(`${element.question.id}_${answer.id}`, referenceFileId, columnName, filterColumn, filterKey);
                }
              }
            }

          }
        }
      }
    }
  }
};

const filterReferenceDynamicDataByKey = async (questionFilterId, answerId) => {
  // Пройдемся по всем экранам
  for (const screen of screens.value) {
    for (const element of screen.elements) {
      if (element.element_type === 'QUESTION' && element.question.question_type === 'GRID' && element.question.id === questionFilterId) {
        // нашли вопрос в элементах, в котором поменялся дропдаун дочернего динамического ряда в элементах
        for (const answer of element.question.answers) {
          if (answer.filter_answer_reference_id && answer.filter_answer_reference_id === answer.id) {
            // нашли ответ в элементах в котором есть информация о дропдауне
            const filterKey = selectedAnswers.value[answerId]
            // переменные справочников динамического ряда
            const rowColumnName = element.question.reference_column;
            // переменные справочников колонки
            const columnReferenceFileId = answer.reference_file;
            const columnColumnName = answer.reference_column;
            if (columnReferenceFileId && columnColumnName) {
              await loadReferenceData(`${element.question.id}_${answerId}_${answer.id}`, columnReferenceFileId, columnColumnName, rowColumnName, filterKey);
            }
          }
        }

      }
    }
  }
};

const filterReferenceDataByColumn = async (questionFilterId, rowQuestionId, answerId) => {
  // Пройдемся по всем экранам
  for (const screen of screens.value) {
    for (const element of screen.elements) {
      if (element.element_type === 'QUESTION' && element.question.question_type === 'GRID' && element.question.id === questionFilterId) {
        // нашли вопрос в элементах, в котором поменялся дропдаун дочернего динамического ряда в элементах
        // нам не нуэно искать ответы и продолжать, если у answerId НЕТ filter_answer_reference_id и 
        for (const answer of element.question.answers) {
          if (answer.filter_answer_reference_id && answer.filter_answer_reference_id != answer.id && answer.filter_answer_reference_id === answerId) {
            // нашли ответ в элементах в котором есть информация о дропдауне
            const filterKey = selectedAnswers.value[rowQuestionId + '_' + answerId]
            // переменные справочников динамического ряда
            const rowColumnName = element.question.reference_column;
            // переменные справочников колонки в которой нужно менять ответ
            const columnReferenceFileId = answer.reference_file;
            const columnColumnName = answer.reference_column;
            if (columnReferenceFileId && columnColumnName) {
              await loadReferenceData(`${element.question.id}_${rowQuestionId}_${answer.id}`, columnReferenceFileId, columnColumnName, rowColumnName, filterKey);
            }
          }
        }

      }
    }
  }
};

// HARDCODE Q-33
const dropdownValues = ref({}); // Содержит значения для всех дропдаунов
const disabledDropdowns = ref({});

const handleFirstDropdownChange = async (rowIndex, rowQuestionId) => {
  // Получаем уникальные значения для первого дропдауна
  const uniqueDropdownOneValues = [
    ...new Set(
      Object.entries(dropdownValues.value)
        .filter(([key]) => key.startsWith('dropdownOne_')) // фильтруем ключи
        .map(([, value]) => value) // берем только значения
    ),
  ];
  console.log(rowQuestionId)
  // Обновляем ответы с уникальными значениями для dropdownOne
  if (surveyId.value === 2) {
    updateSelectedAnswersNext(uniqueDropdownOneValues, '235', 1);
    updateSelectedAnswersNext(uniqueDropdownOneValues, '236', 1);
  } else if (surveyId.value === 6) {
    updateSelectedAnswersNext(uniqueDropdownOneValues, '362', 1);
    updateSelectedAnswersNext(uniqueDropdownOneValues, '363', 1);
  } else if (surveyId.value === 7) {
    updateSelectedAnswersNext(uniqueDropdownOneValues, '486', 1);
    updateSelectedAnswersNext(uniqueDropdownOneValues, '487', 1);
  } else if (surveyId.value === 9) {
    updateSelectedAnswersNext(uniqueDropdownOneValues, '704', 1);
    updateSelectedAnswersNext(uniqueDropdownOneValues, '705', 1);
  }
  const filterKey = dropdownValues.value['dropdownOne_' + rowIndex];
  const referenceKey = 88880 + rowIndex;
  if (filterKey) {
    // Обновите данные для второго дропдауна
    await loadReferenceData(referenceKey, 4, 19, 17, filterKey);
    if (surveyId.value === 2) {
      updateSelectedAnswers(rowIndex, 458, filterKey);
    } else if (surveyId.value === 6) {
      updateSelectedAnswers(rowIndex, 611, filterKey); 
    } else if (surveyId.value === 7) {
      updateSelectedAnswers(rowIndex, 781, filterKey);
    } else if (surveyId.value === 9) {
      updateSelectedAnswers(rowIndex, 1105, filterKey);
    }
  } else {
    // Очистите данные второго дропдауна, если нет фильтра
    referenceOptions.value[referenceKey] = [];
  }
};

const handleSecondDropdownChange = async (rowIndex, rowQuestionId) => {
  console.log(rowQuestionId)
  const filterKey = dropdownValues.value['dropdownTwo_' + rowIndex];
  const referenceKey = 9999 + rowIndex;
  await loadReferenceData(referenceKey, 4, 17, 19, filterKey);
  // Получаем уникальные значения для второго дропдауна
  const uniqueDropdownTwoValues = [
    ...new Set(
      Object.entries(dropdownValues.value)
        .filter(([key]) => key.startsWith('dropdownTwo_')) // фильтруем ключи
        .map(([, value]) => value) // берем только значения
    ),
  ];

  // Обновляем ответы с уникальными значениями для dropdownTwo
  if (surveyId.value === 2) {
    updateSelectedAnswersNext(uniqueDropdownTwoValues, '236', 2);
    updateSelectedAnswers(rowIndex, 459, filterKey);
  } else if (surveyId.value === 6) {
    updateSelectedAnswersNext(uniqueDropdownTwoValues, '363', 2);
    updateSelectedAnswers(rowIndex, 610, filterKey);
  } else if (surveyId.value === 7) {
    updateSelectedAnswersNext(uniqueDropdownTwoValues, '487', 2);
    updateSelectedAnswers(rowIndex, 780, filterKey);
  } else if (surveyId.value === 9) {
    updateSelectedAnswersNext(uniqueDropdownTwoValues, '705', 2);
    updateSelectedAnswers(rowIndex, 1104, filterKey);
  }
};

const updateSelectedAnswers = (rowIndex, answerId, newValue) => {
  const startRow = Math.floor(rowIndex / 9) * 9 + 1;
  const endRow = startRow + 9;
  // Обновите selectedAnswers для всех строк в указанном диапазоне
  for (let i = startRow; i < endRow; i++) {
    let key = ref('')
    if (surveyId.value === 2) {
      key.value = `225_${i}_${answerId}`;
    } else if (surveyId.value === 6) {
      key.value = `358_${i}_${answerId}`;
    } else if (surveyId.value === 7) {
      key.value = `482_${i}_${answerId}`;
    } else if (surveyId.value === 9) {
      key.value = `701_${i}_${answerId}`;
    }
    selectedAnswers.value[key.value] = newValue;
  }
};
// Обновленная функция для проверки пар дропдаунов
const updateSelectedAnswersNext = (uniqueValues, prefix, dropdownNumber) => {
  // Собираем пары значений для дропдаунов
  const dropdownPairs = new Set();

  uniqueValues.forEach((value, index) => {
    let key = ref('');
    if (dropdownNumber === 1) {
      key.value = `${prefix}_${index + 1}`;
    } else {
      if (surveyId.value === 2) {
        key.value = `${prefix}_${index + 1}_383`;
      } else if (surveyId.value === 6) {
        key.value = `${prefix}_${index + 1}_623`;
      } else if (surveyId.value === 7) {
        key.value = `${prefix}_${index + 1}_793`;
      } else if (surveyId.value === 9) {
        key.value = `${prefix}_${index + 1}_1115`;
      }
    }

    // Получаем значения первой и второй пары дропдаунов для текущего индекса
    const dropdownOneValue = dropdownValues.value[`dropdownOne_${index}`];
    const dropdownTwoValue = dropdownValues.value[`dropdownTwo_${index}`];

    // Создаем строку для сравнения пар
    const pairKey = `${dropdownOneValue}_${dropdownTwoValue}`;

    // Проверяем, существует ли уже такая пара
    if (!dropdownPairs.has(pairKey)) {
      // Если пара уникальна, добавляем её в Set и обновляем selectedAnswers и disabledDropdowns
      dropdownPairs.add(pairKey);
      selectedAnswers.value[key.value] = value;
      disabledDropdowns.value[key.value] = value;
    } else {
      console.log(`Duplicate pair found: ${pairKey}, skipping update.`);
    }
  });
};
// HARDCODE Q-33


// HARDCODE Q-26, 27
const answersForTwentySix = ref(new Set());  // Используем Set для уникальности
const answersForTwentySeven = ref(new Set());
const answersForTwentyNine = ref(new Set());
const randomSymptoms = ref([]);

const initializeRandomSymptoms = () => {
  if (surveyId.value === 4) {
    if (referenceOptions.value[320] && referenceOptions.value[320].length > 0) {
      const options = referenceOptions.value[320];
      const uniqueRandomIndexes = new Set();

      while (uniqueRandomIndexes.size < 15) {
        const randomIndex = Math.floor(Math.random() * options.length);
        uniqueRandomIndexes.add(randomIndex);
      }

      // Сохраняем только значения
      randomSymptoms.value = Array.from(uniqueRandomIndexes).map(index => options[index].value); 

    } else {
      console.warn('No options available for referenceOptions[320]');
    }
  } else if (surveyId.value === 8) {
    if (referenceOptions.value[602] && referenceOptions.value[602].length > 0) {
      const options = referenceOptions.value[602];
      const uniqueRandomIndexes = new Set();

      while (uniqueRandomIndexes.size < 15) {
        const randomIndex = Math.floor(Math.random() * options.length);
        uniqueRandomIndexes.add(randomIndex);
      }

      // Сохраняем только значения
      randomSymptoms.value = Array.from(uniqueRandomIndexes).map(index => options[index].value); 

    } else {
      console.warn('No options available for referenceOptions[602]');
    }
  }
  console.log('Initialized random symptoms:', randomSymptoms.value);
};


const collectUniqueAnswers = () => {
  const tempAnswers = new Set();

  if (surveyId.value === 4) {
    // Собираем ответы для 313_1 - 313_10
    for (let i = 1; i <= 10; i++) {
      const answer = selectedAnswers.value[`313_${i}`];
      if (answer) {
        tempAnswers.add(answer);
      }
    }
    // Собираем ответы для 314_1 - 314_10
    for (let i = 1; i <= 10; i++) {
      const answer = selectedAnswers.value[`314_${i}`];
      if (answer) {
        tempAnswers.add(answer);
      }
    }
    // Собираем ответы для 315_1 - 315_10
    for (let i = 1; i <= 10; i++) {
      const answer = selectedAnswers.value[`315_${i}`];
      if (answer) {
        tempAnswers.add(answer);
      }
    }
    // Собираем ответы для 316_1 - 316_10
    for (let i = 1; i <= 10; i++) {
      const answer = selectedAnswers.value[`316_${i}`];
      if (answer) {
        tempAnswers.add(answer);
      }
    }
  } else if (surveyId.value === 8) {
    // Собираем ответы для 595_1 - 595_10
    for (let i = 1; i <= 10; i++) {
      const answer = selectedAnswers.value[`595_${i}`];
      if (answer) {
        tempAnswers.add(answer);
      }
    }
    // Собираем ответы для 596_1 - 596_10
    for (let i = 1; i <= 10; i++) {
      const answer = selectedAnswers.value[`596_${i}`];
      if (answer) {
        tempAnswers.add(answer);
      }
    }
    // Собираем ответы для 597_1 - 597_10
    for (let i = 1; i <= 10; i++) {
      const answer = selectedAnswers.value[`597_${i}`];
      if (answer) {
        tempAnswers.add(answer);
      }
    }
    // Собираем ответы для 598_1 - 596_10
    for (let i = 1; i <= 10; i++) {
      const answer = selectedAnswers.value[`598_${i}`];
      if (answer) {
        tempAnswers.add(answer);
      }
    }
  }
  // Обновляем ответ в ref
  answersForTwentySix.value = Array.from(tempAnswers);

  if (surveyId.value === 4) {
    // Вставляем значения в selectedAnswers 317_1 по 317_10
    for (let i = 1; i <= 10; i++) {
      selectedAnswers.value[`317_${i}`] = answersForTwentySix.value[i - 1] || null; // Заполняем значениями или null
    }
  } else if (surveyId.value === 8) {
    // Вставляем значения в selectedAnswers 599_1 по 599_10
    for (let i = 1; i <= 10; i++) {
      selectedAnswers.value[`599_${i}`] = answersForTwentySix.value[i - 1] || null; // Заполняем значениями или null
    }
  }
  // Добавляем в disabledDropdowns
  if (surveyId.value === 4) {
    answersForTwentySix.value.forEach((value, index) => {
      disabledDropdowns.value[`317_${index + 1}`] = value; // Используем индекс для назначения ключа
    });
  } else if (surveyId.value === 8) {
    answersForTwentySix.value.forEach((value, index) => {
      disabledDropdowns.value[`599_${index + 1}`] = value; // Используем индекс для назначения ключа
    });
  }
};

const collectAnswersForTwentySeven = () => {
  const tempAdditionalAnswers = new Set();

  if (surveyId.value === 4) {
    // Собираем ответы для 313_1_425 - 313_10_425
    for (let i = 1; i <= 10; i++) {
      const answer = selectedAnswers.value[`313_${i}_425`];
      if (answer) {
        tempAdditionalAnswers.add(answer);
      }
    }
    // Собираем ответы для 314_1_427 - 314_10_427
    for (let i = 1; i <= 10; i++) {
      const answer = selectedAnswers.value[`314_${i}_427`];
      if (answer) {
        tempAdditionalAnswers.add(answer);
      }
    }
    // Собираем ответы для 315_1_429 - 315_10_429
    for (let i = 1; i <= 10; i++) {
      const answer = selectedAnswers.value[`315_${i}_429`];
      if (answer) {
        tempAdditionalAnswers.add(answer);
      }
    }
    // Собираем ответы для 316_1_430 - 316_10_430
    for (let i = 1; i <= 10; i++) {
      const answer = selectedAnswers.value[`316_${i}_430`];
      if (answer) {
        tempAdditionalAnswers.add(answer);
      }
    }
  } else if (surveyId.value === 8) {
    // Собираем ответы для 595_1_884 - 595_10_884
    for (let i = 1; i <= 10; i++) {
      const answer = selectedAnswers.value[`595_${i}_884`];
      if (answer) {
        tempAdditionalAnswers.add(answer);
      }
    }
    // Собираем ответы для 596_1_885 - 596_10_885
    for (let i = 1; i <= 10; i++) {
      const answer = selectedAnswers.value[`596_${i}_885`];
      if (answer) {
        tempAdditionalAnswers.add(answer);
      }
    }
    // Собираем ответы для 597_1_887 - 597_10_887
    for (let i = 1; i <= 10; i++) {
      const answer = selectedAnswers.value[`597_${i}_887`];
      if (answer) {
        tempAdditionalAnswers.add(answer);
      }
    }
    // Собираем ответы для 598_1_888 - 598_10_888
    for (let i = 1; i <= 10; i++) {
      const answer = selectedAnswers.value[`598_${i}_888`];
      if (answer) {
        tempAdditionalAnswers.add(answer);
      }
    }
  }

  // Обновляем дополнительные ответы
  answersForTwentySeven.value = Array.from(tempAdditionalAnswers);

  if (surveyId.value === 4) {
    // Вставляем значения в selectedAnswers 318_1 по 318_10
    for (let i = 1; i <= 10; i++) {
      selectedAnswers.value[`318_${i}`] = answersForTwentySeven.value[i - 1] || null;
    }

    answersForTwentySeven.value.forEach((value, index) => {
      disabledDropdowns.value[`318_${index + 1}`] = value; // Используем индекс для назначения ключа
    });
  } else if (surveyId.value === 8) {
    // Вставляем значения в selectedAnswers 600_1 по 600_10
    for (let i = 1; i <= 10; i++) {
      selectedAnswers.value[`600_${i}`] = answersForTwentySeven.value[i - 1] || null;
    }

    answersForTwentySeven.value.forEach((value, index) => {
      disabledDropdowns.value[`600_${index + 1}`] = value; // Используем индекс для назначения ключа
    });
  }
};



// Создаем watcher для отслеживания изменений в selectedAnswers
watch(selectedAnswers, (newValue) => {
  const hasRelevantChanges =
    Object.keys(newValue).some(key => 
      key.startsWith('313_') || 
      key.startsWith('314_') || 
      key.startsWith('315_') || 
      key.startsWith('316_') || 
      key.startsWith('595_') || 
      key.startsWith('596_') || 
      key.startsWith('597_') || 
      key.startsWith('598_') 
    );

  if (hasRelevantChanges) {
    collectUniqueAnswers();
    collectAnswersForTwentySeven();
  }
}, { deep: true });

// Пример использования
collectUniqueAnswers(); 
collectAnswersForTwentySeven();

// Функция для заполнения значений для 320
const collectAnswersForTwentyNine = () => {
  const tempAnswers = new Set();
  let fillCount = 0; // Счетчик для заполненных позиций
 
  if (surveyId.value === 4) {
    // Подставляем уникальные значения из 319_439 - 319_443 в 320_1 - 320_60
    for (let i = 439; i <= 443; i++) {
      const answer = selectedAnswers.value[`319_${i}`];
      if (answer && !tempAnswers.has(answer)) {
        tempAnswers.add(answer);
        // Заполняем по 4 значения для каждого уникального ответа
        for (let j = 0; j < 4; j++) {
          const position = fillCount * 4 + j + 1; // Вычисляем позицию
          if (position <= 60) {
            selectedAnswers.value[`320_${position}`] = answer; // Подставляем ответ
            disabledDropdowns.value[`320_${position}`] = answer; // Добавляем в disabledDropdowns
          }
        }
        fillCount++;
      }
    }

    // Заполняем оставшиеся ответы из randomSymptoms
    for (let i = fillCount * 4 + 1; i <= 60; i++) {
      if (!selectedAnswers.value[`320_${i}`]) { // Проверяем, если значение еще не установлено
        const randomSymptom = randomSymptoms.value[Math.floor((i - 1 - fillCount * 4) / 4)];
        if (randomSymptom) {
          selectedAnswers.value[`320_${i}`] = randomSymptom; // Подставляем из randomSymptoms
          disabledDropdowns.value[`320_${i}`] = randomSymptom; 
        }
      }
    }
  } else if (surveyId.value === 8) {
    // Подставляем уникальные значения из 601_439 - 601_443 в 602_1 - 602_60
    for (let i = 897; i <= 901; i++) {
      const answer = selectedAnswers.value[`601_${i}`];
      if (answer && !tempAnswers.has(answer)) {
        tempAnswers.add(answer);
        // Заполняем по 4 значения для каждого уникального ответа
        for (let j = 0; j < 4; j++) {
          const position = fillCount * 4 + j + 1; // Вычисляем позицию
          if (position <= 60) {
            selectedAnswers.value[`602_${position}`] = answer; // Подставляем ответ
            disabledDropdowns.value[`602_${position}`] = answer; // Добавляем в disabledDropdowns
          }
        }
        fillCount++;
      }
    }

    // Заполняем оставшиеся ответы из randomSymptoms
    for (let i = fillCount * 4 + 1; i <= 60; i++) {
      if (!selectedAnswers.value[`602_${i}`]) { // Проверяем, если значение еще не установлено
        const randomSymptom = randomSymptoms.value[Math.floor((i - 1 - fillCount * 4) / 4)];
        if (randomSymptom) {
          selectedAnswers.value[`602_${i}`] = randomSymptom; // Подставляем из randomSymptoms
          disabledDropdowns.value[`602_${i}`] = randomSymptom; 
        }
      }
    }
  }


  answersForTwentyNine.value = Array.from(tempAnswers);
};

// Создаем watcher для отслеживания изменений в selectedAnswers
watch(selectedAnswers, (newValue) => {
  console.log(newValue)
    collectAnswersForTwentyNine();
}, { deep: true });

// Пример использования
collectAnswersForTwentyNine(); // Вызываем функцию один раз для инициализации
// HARDCODE Q-26, 27


///
// Массив для хранения видимых индексов строк
const visibleRows = ref([]);

// Отслеживание количества показанных дубликатов для каждой группы
const shownDuplicates = ref({});

// Проверка, является ли строка первой в группе (1, 5, 9 и т.д.)
const isFirstRowInGroup = (index) => {
  return (index % 4) === 0;
};

// Проверка, является ли строка дубликатом (например, 2, 3, 4 после 1, 6, 7, 8 после 5 и т.д.)
const isDuplicate = (index) => {
  return (index % 4) !== 0;
};

// Показать следующий скрытый ряд (дубликат) в группе
const showNextHiddenRow = (index) => {
  const groupStart = index;
  const groupEnd = index + 3;

  // Проверяем, сколько дубликатов уже показано в этой группе
  if (!shownDuplicates.value[groupStart]) {
    shownDuplicates.value[groupStart] = 0;
  }

  const nextDuplicateIndex = groupStart + shownDuplicates.value[groupStart] + 1;

  // Если следующий индекс дубликата в пределах группы, показываем его
  if (nextDuplicateIndex <= groupEnd) {
    visibleRows.value.push(nextDuplicateIndex);
    shownDuplicates.value[groupStart]++;
  }
};
///
</script>

<style scoped>
.survey-page {
  padding: 20px;
}

.screen-content {
  margin-bottom: 20px;
}

.screen-element {
  margin-bottom: 15px;
}

.question-error,
.highlight-red {
  color: red;
}

.highlight-red .p-select {
  border-color: red;
}

.grid-table {
  border: 1px solid #cbd5e1;
  border-radius: 8px;
}

.grid-table tr td,
.grid-table tr th {
  padding: 0 0 0 10px;
  max-width: 280px;
  text-align: center;
}
.grid-table tr th {
padding: 10px;
}

/* Кружочки с цифрами для рейтинга */
.rating-circle {
  display: inline-block;
  width: 30px;
  /* Размер кружочка */
  height: 30px;
  border-radius: 50%;
  background-color: #2196f3;
  /* Основной цвет кружочка */
  color: #fff;
  /* Цвет текста внутри кружочка */
  text-align: center;
  line-height: 30px;
  /* Выравнивание текста по центру */
  font-size: 14px;
  /* Размер шрифта для цифры */
  margin: 2px;
  /* Расстояние между кружочками */
}

.rating-circle.active {
  background-color: #1565c0;
  /* Цвет активного кружочка */
}
</style>
<style>
h4 {
  font-weight: 500;
  font-size:16px;
}
label, p, span, .p-button-link {
  font-size: 14px;
}
td p {
  margin: 10px 0;
}
tr {
  border-bottom: 1px dashed #edebeb;
}
.num-block .p-inputnumber-input,
.grid-table .p-inputnumber-input {
  width: 65px;
  height: 33px;
  font-size: 14px;
  padding: 5px;
}
thead {
  border-bottom: 1px solid #dedede;
}
thead th {
margin: 10px 0;
}

.table-container {
  display: block;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  /* для плавного скролла на iOS */
}

.grid-table {
  border-collapse: collapse;
}

.grid-table thead th:first-child,
.grid-table tbody td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background: white;
  min-width: 150px;
  text-align: left;
}

table .highlight-red>.p-select {

  border-color: red;

}

#public table .p-select {
  width: 100% !important;
  margin: 9px 0;
}

.hidden-duplicate, .do-not-show {
  display: none;
}
/*
@media only screen and (max-width: 768px) {
  .grid-table {
    width: 100%;
    display: block;
  }

  .grid-table thead {
    display: none;
  }

  .grid-table tbody, .grid-table tr {
    display: block;
    width: 100%;
  }

  .grid-table tr {
    margin-bottom: 1em;
    border: 1px solid #ddd;
    padding: 10px;
  }

  .grid-table td {
    display: block;
    text-align: right;
    padding: 10px;
    position: relative;
  }

  .grid-table td:before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    top: 10px;
    font-weight: bold;
    text-align: left;
  }
}
  */
</style>